import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TitleComp from "./TitleComp";
import CardOne from "./CardOne";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import CardDetail from "./CardDetail";
import ModalOne from "../components/ModalOne";
import { AppContext } from "../App";
import CardDetailWallet from "./CardDetailWallet";
import { API_URL } from "../config/default";
import { auth } from "../services/firebase";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWeb3Functions } from "../hooks/useWeb3Functions";
import { useAccount } from "wagmi";

function TabPanel(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingLeft: 0 }}
    >
      {value === index && (
        <Box sx={{ py: 3, px: matches && 3, pl: matches ? 3 : 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabComp = () => {
  const { loginResponse, walletLogin, setWalletLogin, setLoginPressed } =
    useContext(AppContext);

  const { open: openWeb3 } = useWeb3Modal();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const { address, isConnected } = useAccount();

  const updatePublicAddress = useCallback(
    (publicAddress) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", loginResponse?.token);

      var raw = JSON.stringify({
        publicAddress,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(`${API_URL}api/profile/updatePublicAddress`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("updatePublicAddress", res);
          setWalletLogin(publicAddress);
        })
        .catch((error) => console.log("error", error));
    },
    [loginResponse?.token, setWalletLogin]
  );
  useEffect(() => {
    if (isConnected && address !== walletLogin && loginResponse) {
      console.log("buradayız", isConnected);
      updatePublicAddress(address);
    }
  }, [isConnected, address, updatePublicAddress, loginResponse, walletLogin]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const btnhandler = () => {
    if (loginResponse) {
      openWeb3();
    } else {
      setLoginPressed(true);
    }
  };

  return (
    <Box sx={{ width: "100%", p: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 0 }}>
        <div>
          <Tabs
            // inkBarStyle={{ background: 'yellow' }}
            sx={{
              "& .MuiTab-root": {
                color: "#8E909A !important",
                "&.Mui-selected": {
                  color: "white !important",
                },
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "red",
              },
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab label="Wallet" />
          </Tabs>
        </div>
      </Box>
      <TabPanel value={value} index={value}>
        {value === 0 && (
          <Grid container direction="row" spacing={3}>
            <Grid item sm={6} xs={12} sx={{ pl: 0 }}>
              <TitleComp title="GamerArena Wallet" />

              {!loginResponse ? (
                <CardOne buttonn text="Login to see your balance" />
              ) : (
                <CardDetail />
              )}
            </Grid>
            <Grid item sm={6} xs={12} sx={{ pl: 0, height: "100%" }}>
              <TitleComp title="Connected Wallet" />
              <ModalOne open={open} setOpen={setOpen} />
              {!walletLogin || !isConnected ? (
                <CardOne
                  icon
                  textPlus="Connect wallet"
                  onClickWallet={btnhandler}
                />
              ) : (
                <CardDetailWallet />
              )}
            </Grid>
          </Grid>
        )}
      </TabPanel>
    </Box>
  );
};

export default TabComp;
