import React from "react";

const TermsOfServicePage = () => {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh2\n\t{margin-top:.25in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\nh4\n\t{margin-top:14.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:12.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#666666;\n\tfont-weight:normal;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:26.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n',
        }}
      />
      <div style={{ margin: "12px 16px" }} className="WordSection1">
        <h2 id="-terms-of-use-">
          <strong>TERMS OF USE</strong>
        </h2>
        <h3 id="-1-general-information-">
          <strong>1. General Information</strong>
        </h3>
        <ol>
          <li>
            Below are the Terms and Conditions to be accepted by the player
            during the registration process.
          </li>
          <li>
            Gamerarena.com is operated by Gamer Arena Teknoloji Hizmetleri A.Ş.,
            a company incorporated under Turkish Laws. Gamer Arena Teknoloji
            Hizmetleri A.Ş. was registered on 07.03.2022 with registration
            number 4661488051 and has its registered office at Abbasağa Mah.
            Yıldız Cad. Sungurlar Iş Merkezi No:45 İç kapı No:7
            Beşiktaş/İstanbul
          </li>
          <li>
            Gamer Arena Teknoloji Hizmetleri A.Ş. makes no representations as to
            the legality of its service in your country of residence. This
            agreement is governed by and shall be construed in accordance with
            the laws of Turkish. The place of jurisdiction is Turkish.
          </li>
          <li>
            Gamer Arena is referred to as &quot;GA&quot;, &quot;us&quot;, or
            &quot;our&quot;, and the player as &quot;you&quot; or
            &quot;player&quot;.
          </li>
          <li>
            &quot;Games&quot; and &quot;Game&quot; are referred to as any of the
            game products offered by this website/application(s).
          </li>
          <li>
            The term “Application” shall be referred to any/all the platforms
            (Apart from the GA’s Web site) where GA arranges games, duels or
            competitions.
          </li>
          <li>
            There is only one version of these Terms and Conditions. These Terms
            and Conditions may be published in several languages to reflect the
            same principles, for informational purposes, and to assist players.
            However, the legal basis for the relationship between you and GA is
            only in the English version. In the event of any inconsistency
            between the English version and the non-English version of these
            Terms and Conditions, the English version shall prevail.
          </li>
          <li>
            These Terms and Conditions constitute a legally binding agreement
            between the player and GA, and gamerarena.com has the right to
            govern your use of the website. Please read these terms and rules
            carefully. GA reserves the right to make changes to these Terms and
            Conditions without notice. GA shall require the player to accept the
            revised Terms and Conditions, ensuring that the player is notified
            of such changes the next time they log into the website. If the
            Player refuses the changes, the Player shall be blocked from using
            the gamerarena.com service. These Terms and Conditions shall be kept
            available and accessible to players at all times.
          </li>
          <li>
            Players are responsible for regularly reviewing the terms and
            conditions to ensure they accept the terms to which the services are
            subject. It is also recommended that you review these Terms and
            Conditions each time you use the website.
          </li>
          <li>
            These Terms and Conditions apply once you complete the registration
            process on the website. By creating an account on Gamer Arena, you
            declare that you have read and accepted these Terms and Conditions.
          </li>
          <li>
            All necessary information regarding the game rules, any commissions
            or other fees charged by us shall be kept accessible at all times.
            This information is available in this agreement.
          </li>
        </ol>
        <h3 id="-2-your-obligations-">
          <strong>2. Your Obligations</strong>
        </h3>
        <ol>
          <li>
            A user can only open one account on the website. GA has the right to
            close the account in cases of multiple account detection.
          </li>
          <li>
            By accepting this agreement, you acknowledge that you are over the
            age of 13 and are subject to the laws applicable in your country of
            residence.
          </li>
          <li>
            You are solely responsible for specific account details such as your
            username and password and any other linked email addresses used on
            the website as a means to access your account. In addition, you are
            responsible for the security of any personal computer or device on
            which you have registered your account and where your account can be
            accessed. If you suspect that such information has been compromised,
            you should notify us and take appropriate measures to prevent
            unauthorized access to any part of your account. GA cannot be held
            responsible for any unauthorized access to your account while GA is
            not at fault.
          </li>
          <li>
            You can only play and take part in the games for yourself. You may
            not operate on behalf of another person or company.
          </li>
          <li>
            You may participate in the Games for entertainment purposes only, in
            a non-professional capacity.
          </li>
          <li>
            By accepting this agreement, you agree that you are not located in
            any country that prohibits competitive gaming.
          </li>
          <li>
            You are not allowed to transfer funds from your account to other
            players, or transfer funds from other players to your account, or
            transfer, sell, and/or own user accounts.
          </li>
          <li>
            You may participate in any game only if you have sufficient funds in
            your account for such participation.
          </li>
          <li>
            You may not use any prohibited or illegally associated balance from
            any illegal activity or source.
          </li>
          <li>
            You are solely responsible for reporting and accounting for any
            taxes or other charges applicable to you under applicable law for
            any gains you receive from GA.
          </li>
          <li>
            You may only use credit cards and other payment methods that are
            valid and legally owned by you for depositing and withdrawing funds
            from your account.
          </li>
          <li>
            In connection with your participation in any of the Games, you
            undertake that you will not be involved in any fraudulent, cheating,
            fixing, or other illegal activity and that you will not use any
            third-party software-supported methods or technical, exploitative
            hardware devices. In case of such behaviour, we reserve the right to
            void your participation in duels/games, reclaim any wages/progress
            earned, cancel your games.
          </li>
          <li>
            You may use the Game that we designed for solely entertainment
            purposes, only if it complies with the rules, terms &amp; conditions
            we have created as well as all applicable laws, rules, and
            regulations.
          </li>
          <li>
            You must be supportive of other players using the website, as well
            as staff employed by GA, and refrain from making rude or obscene
            comments.
          </li>
          <li>
            If you breach any of the obligations specified below, the Company
            may recourse to you for any damages, including those to third
            parties, or may take legal actions and remedies to compensate for
            the damages, and may suspend or close your account, provided that
            you are notified when necessary. The Company evaluates the legality
            of the information provided and its compliance with obligations,
            taking into account fundamental rights and freedoms such as freedom
            of expression and legality reasons such as public interest.
          </li>
          <li>
            All personal data entered by you on the platform must be accurate
            and up-to-date.
          </li>
          <li>
            If requested by the Company, your personal data must be verified and
            approved.
          </li>
          <li>
            If changes or updates are required in personal data, the relevant
            sections must be updated. Otherwise, your current information shall
            be deemed to be correct.
          </li>
          <li>
            The account belongs only to you. Therefore; you agree ● To choose a
            strong and secure password; ● To keep the password safe and
            confidential; ● Not to give any part of the account to anyone else
            and ● To comply with the laws and Terms of Use in this context.
          </li>
          <li>
            Even if all reasonable precautions are taken, you are responsible
            for any actions taken regarding your account, other than the
            hijacking of your account by unrelated persons, or for systemic
            reasons beyond your control.
          </li>
          <li>
            It is expressly prohibited to open an
            imitation/parody/fake/misleading account on the platform by using
            any person&#39;s information.
          </li>
          <li>
            You may not include personal data, especially sensitive personal
            data, of unrelated third parties on the platform unless it is
            necessary and suitable for its purpose, and you cannot violate
            confidentiality agreements with any legal or natural person.
          </li>
          <li>
            You cannot include content such as insults, slander, threats, etc.,
            which can be considered crimes under the Turkish Penal Code No.
            5237, on the platform.
          </li>
          <li>
            You may not include content that promotes or supports illegal or
            criminal activities or organizations on the platform.
          </li>
          <li>
            You cannot open more than one account, put your account on sale, and
            have others use it for your own benefit or the benefit of a third
            party, for deception, spoofing, unfair gain, or other reasons.
          </li>
          <li>
            You cannot include content that violates the intellectual property
            rights of third parties, such as trademarks and copyrights.
          </li>
        </ol>
        <h3 id="-3-your-account-">
          <strong>3. Your Account</strong>
        </h3>
        <ol>
          <li>
            Before you can duel and deposit money, you must first personally
            register with Gamer Arena and open an account (&quot;Account&quot;).
          </li>
          <li>
            Within the scope of the membership and for creating an account, you
            give your consent in advance for the Company to create an account
            and profile for you from all the information you have provided and
            shared on the Platform, including KYC processes. In this context,
            any legal, criminal, and/or administrative responsibility in
            disputes that may arise regarding the accuracy of the information
            shared belongs to you.
          </li>
          <li>
            You are only allowed to have one account and register it personally.
            If you try to open more than one account, any account you try to
            open may be blocked or closed, and any deposited/earned balances may
            be voided.
          </li>
          <li>
            You agree that all the information you provide to us, including but
            not limited to valid ID, and email, is complete for the duration of
            this agreement. Likewise, you agree that you will promptly notify GA
            of any changes to such information or that you will change such
            details yourself on the website.
          </li>
          <li>
            In the event that such information changes, you must update the
            mandatory information provided in the registration form and keep it
            up to date.
          </li>
          <li>
            If you notice that you have more than one registered account, you
            must notify us immediately. Failure to do so may result in your
            account being blocked.
          </li>
          <li>
            As part of the registration process, you will need to set your
            username and password to login to the website. It is your sole and
            exclusive responsibility to ensure that your login information is
            kept secure. You should not share your login information with
            anyone. We are not responsible for any third-party abuse or misuse
            of your account if you disclose your login information to any third
            party, whether your account is active or inactive, and whether the
            disclosure is intentional or accidental.
          </li>
          <li>
            GA reserves the right, in its sole discretion, to refuse to open or
            close an account. However, any contractual obligations already made
            by GA shall be fulfilled accordingly, without prejudice to GA&#39;s
            statutory rights.
          </li>
          <li>
            If you want to close your account, you can contact the live support
            section of our website. You can do this whenever you want. The
            effective closure of the account will correspond to the termination
            of the Terms and Conditions.
          </li>
          <li>
            In the event a user account is closed, account-related information
            is retained for up to five (5) years from the moment the account is
            closed. Relevant information includes player name, date of birth,
            country, language, email, and account transaction details
          </li>
        </ol>
        <h3 id="-4-balance-loading-">
          <strong>4. Balance Loading</strong>
        </h3>
        <ol>
          <li>
            We may charge a transaction fee at the time of balance loading. If
            such fees occur, they will appear during the balance loading
            process.
          </li>
          <li>
            There are minimum and maximum deposit levels as shown on the
            website.
          </li>
          <li>
            Deposits into your account are made using any of the methods
            specified on the website. These methods may be changed from time to
            time. Details regarding withdrawal timings for the method used are
            available on the respective pages of the website, as may be changed
            from time to time.
          </li>
          <li>
            Deposits can be used in the account within a reasonable time after
            confirmation of balance loading on all available deposit options
            unless extra verification procedures are required as set out in 4.5.
          </li>
          <li>
            We reserve the right to use additional procedures and tools
            (customer identification) to verify your identity when depositing
            funds into your account.
          </li>
          <li>
            If GA, due to technical or human error or for any other reason,
            loads balance to your account with amounts that do not belong to
            you, the transferred balance shall remain the property of GA and the
            amount will be withdrawn from your account. If, before GA became
            aware of the error, you withdrew balances that did not belong to
            you, without prejudice to other remedies and actions that may be
            available at law, the amount paid in error shall constitute a debt
            owed to us. In the event of an incorrect balance, you must
            immediately notify GA via email.
          </li>
          <li>
            You can only use your valid and legal credit card and other payment
            methods.
          </li>
          <li>The minimum balance withdrawal amount is 1000 GA Coins.</li>
          <li>
            GA accepts payments made in TL via credit card and/or e-wallet on
            its website. The balances you withdraw will be sent to you in your
            GA Account currency, converted into Turkish Lira or transferred to
            your e-wallet in the form of “GA Coins”. Please note that any
            exchange costs shall be paid by you.
          </li>
          <li>
            GA reserves the right to use additional procedures and methods to
            verify your identity before and after depositing funds into your
            account. It is illegal to deposit money obtained illegally.
          </li>
        </ol>
        <h3 id="-5-balance-withdrawal-">
          <strong>5. Balance Withdrawal</strong>
        </h3>
        <ol>
          <li>
            Available withdrawal options shall be clearly stated on the website,
            and these options may differ depending on the player&#39;s location.
          </li>
          <li>
            Withdrawals will only be credited to the account where the funds
            paid to the player&#39;s account were held. GA reserves the right to
            take all necessary measures to confirm all details. This will be
            done within a reasonable time frame.
          </li>
          <li>
            Payment managers employed by GA shall be able to implement
            additional verification procedures for payments or accumulated
            withdrawals. We reserve the right to apply such verification
            procedures also in cases of lower payments. Such verifications may
            include copies of a member&#39;s passport and/or copies of a
            member&#39;s utility bills/electricity bills.
          </li>
          <li>
            Withdrawal processing times may vary depending on circumstances, but
            transactions are confirmed or rejected within two (2) weeks,
            following the authentication and KYC procedures. However, if the
            user fails to pass the KYC examination procedure successfully, and
            if it is determined that he has a connection with fraud, money
            laundering or terrorism, the money may not be transferred to the
            user&#39;s account under any circumstances, at GA’s sole discretion.
            The user agrees that GA has no responsibility in such a case.
          </li>
          <li>
            If you win GA Coins in an amount of € 100,000 or more and you decide
            to withdraw a large portion of your earnings (more than 50%), we are
            entitled to split the payment into ten instalments, with 10% paid
            each month for ten months up to the full amount. You will not
            receive any interest on the outstanding amount.
          </li>
          <li>
            Please note that your balance is used during the game. Therefore, we
            cannot provide repayment, balance refunds, or cancellation of your
            service while playing the game.
          </li>
          <li>
            GA is not a banking institution, and therefore you should not expect
            any interest payments from GA.
          </li>
          <li>
            We may charge dedicated fees for withdrawals, and this will be
            clearly stated when you request a withdrawal.
          </li>
        </ol>
        <h3 id="-6-game-rules-">
          <strong>6. Game Rules</strong>
        </h3>
        <ol>
          <li>
            As part of the Services, the player may enter certain competitive
            multiplayer skill-based paid games (&quot;Games&quot;). The winner
            of each game shall be determined based on their skills in relation
            to other users competing in the game.
          </li>
          <li>
            The Player explicitly accepts and declares that Apple (“Apple Inc.”)
            is not a sponsor of any contest or sweepstake rules that shall
            partake in GA’s website or application(s). To avoid any doubt Apple
            does not undertake any of the games/duels/sweepstakes that shall
            occur in the website or GA’s application(s).
          </li>
          <li>
            The entry fee and value of the amount offered in each game will be
            announced before the start of each game. The player can refund the
            entry fee for any game. However, the player accepts and declares
            that any entrance fee paid after the game has started will not be
            refunded and the player will not be able to win the game. At the
            start of the game, all entrance fees paid will be charged to the
            player&#39;s main payment method. The player will be solely
            responsible for the payment of all entry fees.
          </li>
          <li>
            After the game that will partake in the website of GA, users will
            report the game results to GA under the terms of the Services, even
            if the game closes early. GA shall have sole and absolute discretion
            to determine the results and winners of the Duel, and by
            participating in any duel, the player agrees to abide by these
            determinations. After the duel winner is determined by GA, GA shall
            receive ten percent (10%) of all prize amounts won by the duel
            winner. The remaining prize amount will then be credited to or
            transferred to the duel winner&#39;s account.
          </li>
          <li>
            As for the games hosted on the GA&#39;s application(s), the
            application’s internal software will determine the game results. GA
            shall have sole and absolute discretion to determine the results and
            winners of the Duel, and by participating in any duel, the player
            agrees to abide by these determinations. After the duel winner is
            determined by GA, GA shall receive ten percent (10%) of all prize
            amounts won by the duel winner. The remaining prize amount will then
            be credited to or transferred to the duel winner&#39;s account.
          </li>
          <li>
            The player is solely responsible for complying with the rules we set
            for the games within the site. In case of non-compliance, GA has the
            right to disqualify the user from the game, and also GA has the
            right to fine and/or suspend the Player&#39;s account in GA.
          </li>
        </ol>
        <h3 id="-7-exceptional-circumstances-">
          <strong>7. Exceptional Circumstances</strong>
        </h3>
        <ol>
          <li>
            Circumstances beyond GA&#39;s control, such as unexpected technical
            issues or technical issues involving third-party providers, allow GA
            to cancel the game and refund players.
          </li>
          <li>
            GA reserves the right to limit balances, cancel balances, and refuse
            to pay when the balances are considered to be too high or if the
            player&#39;s actions have taken place in such a way that the system
            has been abused.
          </li>
          <li>
            If a refund is decided, the refund amount shall be immediately
            credited to the player&#39;s account.
          </li>
          <li>
            In the event that access to a game is interrupted in a way that
            cannot be rectified, for example, in the event of a loss of
            connection while playing, GA has the right to regularly
            &quot;clear&quot; such entry fees and return the entry fees to the
            player&#39;s account. If the game has been stopped or crashed on the
            server, the player shall be refunded.
          </li>
          <li>
            If a game has a bug or misconfiguration that causes misbehaviour or
            payouts, GA has the right to remove the game from the system and
            change players&#39; balances and account details to fix the bug.
          </li>
          <li>
            GA is not responsible for any interruptions, server interruptions,
            delays, or any technical or system disturbances in the game. Refunds
            may only be granted at management&#39;s discretion.
          </li>
          <li>
            GA accepts no liability for any damage or loss assumed or alleged to
            arise out of or in connection with the website or its content,
            including, without limitation, delays or interruptions in operation
            or transmission, loss or contamination of data, communication or
            line failures, misuse of the website or its content by any person,
            or any errors or omissions in the content.
          </li>
          <li>
            GA may remove any game from its website/application(s) at any time
            it deems appropriate.
          </li>
        </ol>
        <h3 id="-8-inactive-accounts-">
          <strong>8. Inactive Accounts</strong>
        </h3>
        <ol>
          <li>
            An inactive account is an account that has not been accessed for
            twelve (12) months and has a balance.
          </li>
          <li>
            Up to thirty (30) days prior to the deactivation of your Account, GA
            shall contact you via e-mail, notifying you that your account will
            be deactivated and that all fees that may be charged in connection
            therewith are included in the account. GA shall also offer the
            option to withdraw balances in the account.
          </li>
          <li>
            GA shall reimburse these fees if you access your account within
            three (3) months from the date the inactive account fee was
            incurred.
          </li>
          <li>
            GA may charge an inactive account as long as it does not create a
            negative balance.
          </li>
          <li>
            If you have not logged into the account for a long time and are not
            in a position to accept any material changes to the Terms and
            Conditions, all fees charged to the account shall be in accordance
            with the Terms and Conditions you most recently accepted.
          </li>
          <li>
            The procedure for removing balances from inactive accounts is done
            automatically and reported in the software system of the website
            management interface.
          </li>
        </ol>
        <h3 id="-9-privacy-policy-">
          <strong>9. Privacy Policy</strong>
        </h3>
        <ol>
          <li>
            You acknowledge and agree that we collect and use your personal data
            to allow you to access and use the website and to participate in
            games.
          </li>
          <li>
            We agree to be bound by the General Data Protection Regulation (EU)
            2016/679 when collecting your personal information, as stated in the
            previous clause. We will protect your personal information and
            respect your privacy in accordance with best business practices and
            applicable laws.
          </li>
          <li>Identity and contact information:</li>
          <li>Data Controller: Abdullah Özşahin.</li>
          <li>Data Protection Officer: Abdullah Özşahin</li>
          <li>
            Your personal data will be used by us to allow you to participate in
            the games and to take actions related to your participation in the
            games.
          </li>
          <li>
            We may also use your personal data to inform you about promotions,
            changes, and new services that we think you may find interesting. To
            receive such direct marketing data, you must register for such
            service by managing your settings or contacting support.
          </li>
          <li>
            When you open an account with us, your personal data may be
            processed for the purpose of preventing money laundering.
          </li>
          <li>
            Your personal data will not be given to third parties unless
            necessary to process your requests regarding your participation in
            the games unless specifically permitted by you or unless required by
            law. As GA&#39;s business partners, suppliers, or service providers
            may be responsible for the overall or certain parts of the operation
            of the website, personal data may be transmitted to them. Relevant
            authorities and GA employees, especially the customer support line,
            payments team, and other employees, may access your personal data to
            perform their duties and provide you with assistance and services.
            You are deemed to have accepted such disclosures.
          </li>
          <li>
            We may even collect a cookie from your browser to make the website
            experience better. If you wish, you can turn off the collection of
            cookies. However, you should remember that turning off cookies may
            restrict your proper use of the website.
          </li>
          <li>
            In order to provide you with an efficient service, we and our
            service providers may need to transfer your personal data from one
            country to another in the EU and EFTA regions.
          </li>
          <li>
            Transactions are checked to prevent money laundering, and suspicious
            transactions are reported to the relevant authorities.
          </li>
          <li>
            Our retention period is reserved for data that does not exceed 1
            year.
          </li>
          <li>
            While you have the right to access, correct, delete, and restrict
            your data, you also have the right to make data portability
            requests.
          </li>
          <li>
            GA does not knowingly collect personal data from children. Since the
            players below age of 13 are not permitted in the system, in cases of
            violation of this GA shall not be responsible for the consequences.
          </li>
          <li>
            You can object to the processing of your data and withdraw your
            consent if the processing is based on your consent.
          </li>
          <li>
            You have the opportunity to lodge a complaint with the relevant
            supervisory authority for all actions.
          </li>
          <li>
            The Company has the right to contact you, to notify you about new
            products and system improvements, and to reach and send e-mails to
            you for other purposes via the contact addresses specified by you
            during registration, membership, and account creation on the
            Platform, or updated by you later. By accepting these Terms of Use,
            you accept and declare that the Company may engage in the
            above-mentioned communication activities unless otherwise stated.
            You can unsubscribe from the e-mail list with one click through the
            account settings or by using the &quot;I want to unsubscribe&quot;
            link in the e-mail sent to you.
          </li>
          <li>
            Playtime (adjoe) is a third feature that is integrated with our app.
            Playtime allows users to earn in-app currency by playing third-party
            games and collects devices’ application usage from users. The
            processing of the data is necessary to recommend, via system
            messages, the installation of the app available in Playtime that
            matches users’ interests and calculates the bonuses acquired as a
            result of users’ use of the corresponding apps. For more
            information, please refer to Playtime (adjoe) Data Privacy.”
            <a href="https://adjoe.zone/legal/datenschutz.html">
              https://adjoe.zone/legal/datenschutz.html
            </a>
            .
          </li>
          <li>
            GA shall not be responsible for any of the rewards that the players
            should receive for participating in the advertisements. These
            rewards shall be granted by the advertiser. To avoid any doubt GA
            does not undertake to grant rewards related to the advertisements
            within the website/application(s). Additionally, GA shall not be
            liable for any damages that may incur due to advertisements placed
            within the website/application(s).
          </li>
        </ol>
        <h3 id="-10-anti-money-laundering-">
          <strong>10. Anti-Money Laundering</strong>
        </h3>
        <ol>
          <li>
            All transactions will be checked to prevent money laundering. GA
            shall report all suspicious transactions to the relevant authorities
            in Turkey. If GA becomes aware of any suspicious activity related to
            any of the games on its website, we may report it to the Financial
            Intelligence Analysis Unit (FIAU). GA may suspend, block, or close
            an account and withhold its balances upon request under the
            Anti-Money Laundering Act.
          </li>
          <li>
            GA&#39;s obligations under AML legislation preclude commercial
            terms.
          </li>
        </ol>
        <h3 id="-11-player-complaints-">
          <strong>11. Player Complaints</strong>
        </h3>
        <ol>
          <li>
            GA strives to make a player&#39;s experience with us enjoyable, but
            there may be occasions when a player may not be satisfied with the
            quality of our products or customer service. The player can make a
            complaint by sending an email to Customer Service at
            [info@gamerarena.com]. Your complaint is forwarded to management,
            which tries to resolve the complaint within 48 hours of receiving
            it.
          </li>
          <li>
            If your complaint is not resolved by the above procedures, you may
            file a claim with the Turkish Arbitration Center as a registered
            Alternative Dispute Resolution.
          </li>
        </ol>
        <h3 id="-12-limitation-of-liability-">
          <strong>12. Limitation of Liability</strong>
        </h3>
        <ol>
          <li>
            You enter the website/application(s) and participate in the games at
            your own risk. Websites/application(s) and games are provided
            without warranty of any kind, either expressed or implied.
          </li>
          <li>
            Without prejudice to the generality of the previous paragraph, we,
            our directors, employees, partners, and service providers;
          </li>
          <li>
            Do not guarantee that the software, games, and
            website/application(s) are suitable for your purposes;
          </li>
          <li>
            Do not guarantee that the software, games, and
            website/application(s) are error-free;
          </li>
          <li>
            Do not guarantee that the software, games, and
            website/application(s) will be available uninterruptedly;
          </li>
          <li>
            GA shall not be liable for any direct, indirect, special,
            consequential, or any other loss, cost, expense, or damage arising
            out of your use of the website/application(s) or participation in
            the games.
          </li>
          <li>
            You understand and agree that in the event of a failure in a game or
            its co-accessibility, duels made during such failure will be void.
            Any subsequent game rounds entered with such balances will be
            voided, regardless of the proceeds from a faulty game and which
            games were played with those proceeds.
          </li>
          <li>
            You hereby agree to fully indemnify us, our managers, employees,
            partners, and service providers for any and all costs, expenses,
            losses, damages, claims, and liabilities that may arise in
            connection with your use of or participation in the
            website/application(s) and the games.
          </li>
          <li>
            During the account creation of the member, if the platform redirects
            you to another pop-up page or tab, the information you fill in on
            this page and the files you upload are processed and saved in the
            systems of the said company or third party, not in the Company&#39;s
            systems. The Company has no right to intervene in such and similar
            cases. When any information is entered by you by following the links
            given on the Platform, the Company has no responsibility for the
            content and accuracy of this information.
          </li>
          <li>
            The Company has no obligation to investigate the accuracy of the
            information you provide and share on the Platform, including the
            information you provide in KYC processes, within the scope of
            Membership and to create an account. Any legal, criminal and/or
            administrative responsibility for the disputes that may arise
            regarding the accuracy of the information provided shall be yours.
          </li>
        </ol>
        <h3 id="-13-violations-penalties-and-termination-">
          <strong>13. Violations, Penalties and Termination</strong>
        </h3>
        <ol>
          <li>
            If you violate any provision of these Terms and Conditions, or if we
            have reasonable grounds to suspect that you have violated them, we
            reserve the right to withhold, suspend, close your account, or
            withhold payment of your earnings and apply any damage to your
            balance.
          </li>
          <li>
            If GA suspects that you have engaged in illegal and/or fraudulent
            activities while using the website, or that you are in breach of
            this agreement, have problems with creditors, or otherwise harm our
            business, we may, in our sole discretion, freeze or terminate your
            account or cancel any rewards.
          </li>
          <li>
            You agree that GA shall be the final decision maker as to whether
            you violate our rules, Terms and Conditions, resulting in a
            suspension or permanent ban from your participation in our site(s).
          </li>
        </ol>
        <h3 id="-14-entire-agreement-">
          <strong>14. Entire Agreement</strong>
        </h3>
        <ol>
          <li>
            If any provision of these Terms and Conditions is found to be
            illegal or unenforceable, such provision(s) shall be removed from
            these Terms and Conditions and all other provisions shall remain in
            effect unaffected by such interruption.
          </li>
          <li>
            We reserve the right to assign or otherwise legally transfer this
            agreement. You may not transfer or otherwise lend this contract.
          </li>
          <li>
            These Terms and Conditions constitute the entire agreement between
            you and us regarding the website and supersede all prior or
            simultaneous communications and offers between you and us, whether
            electronic, verbal or written.
          </li>
        </ol>
        <h3 id="-15-applicable-law-and-jurisdiction-">
          <strong>15. Applicable Law and Jurisdiction</strong>
        </h3>
        <ol>
          <li>
            These Terms and Conditions shall be governed by the Laws of Turkey.
          </li>
          <li>
            The parties agree that any dispute or claim arising out of or in
            connection with these Terms and Conditions, or any breach,
            termination or invalidity thereof, shall be submitted to the
            exclusive jurisdiction of the Courts of Turkey.
          </li>
        </ol>
        <h3 id="-16-purposes-of-processing-your-personal-data-">
          <strong>16. Purposes of Processing Your Personal Data</strong>
        </h3>
        <ol>
          <li>
            In order to complete your membership procedures and participation in
            the platform, as well as to complete the KYC processes, and to take
            precautions regarding privacy and security, the information you
            share with us through the platform during and after account creation
            and membership processes is shared with third parties.
          </li>
          <li>
            When you open an account on the GA website or application(s), your
            information may be shared
          </li>
          <li>
            In order to evaluate whether the account and account conditions are
            met,
          </li>
          <li>
            To examine the accounts and to identify new users who create/will
            create an account,
          </li>
          <li>
            To verify your identity data and record your information for future
            confirmation,
          </li>
          <li>
            With our lawyers, to exercise our right of defence when necessary.
          </li>
          <li>
            In order to provide services, we share the data with third parties
            based abroad and with our service providers who are based abroad and
            with whom we have a data controller/data processor relationship.
          </li>
          <li>
            Your personal data shall be retained for the maximum period
            specified in the relevant legislation or required for the purpose
            for which they are processed, and in any case, for the statutory
            limitation periods
          </li>
        </ol>
      </div>
    </>
  );
};

export default TermsOfServicePage;
