import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAccount, useDisconnect, WagmiProvider } from "wagmi";
import { avalanche } from "viem/chains";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { SnackbarProvider } from "notistack";

import "./App.css";
import { API_URL } from "./config/default";
import { auth } from "../src/services/firebase";
import Footer from "./components/Footer/Footer";
import Router from "./routes";

export const AppContext = createContext();

export const projectId = "86c67c4282dec68752febf344ab251f3";
const queryClient = new QueryClient();

const metadata = {
  name: "Gamer Arena Wallet",
  description: "Wallet",
  url: "https://wallet.gamerarena.com/",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};
const chains = [avalanche];
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
  defaultChain: avalanche,
  allowUnsupportedChain: false,
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
    "225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f",
  ],
  themeVariables: {
    "--w3m-z-index": 999999,
    // "--w3m-color-mix-strength": 40,
  },
});

export function AppContextWrapper({ children }) {
  const [loginResponse, setLoginResponse] = useState(null);
  const [walletId, setWalletId] = useState(null);
  const [coinInfos, setCoinInfos] = useState(null); //coin bilgileri
  const [loginPressed, setLoginPressed] = useState(false); //Sign in modalı açar
  const [walletLogin, setWalletLogin] = useState(false);
  const [isWalletEquel, setIsWalletEquel] = useState(false);
  const [AvailableWithdrawAmount, setAvailableWithdrawAmount] = useState("");
  const [openWallet, setOpenWallet] = useState(false);
  const [data, setData] = useState([]);
  const [currentLimitOfset, setcurrentLimitOfset] = useState({
    skip: 0,
    limit: 10,
  });
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [text, setText] = useState("");
  const getUserWallet = useCallback(
    (token) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(`${API_URL}api/profile/myWallet`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          const publicAddress = res?.[0]?.publicAddress;
          setLoginResponse((prev) => ({
            ...prev,
            wallet: res?.[0]?.count?.$numberDecimal,
          }));
          if (publicAddress) setWalletLogin(publicAddress);
        })
        .catch((error) => console.log("error", error));
    },
    [setLoginResponse, setWalletLogin]
  );
  useEffect(() => {
    const webToken = window.localStorage.getItem("Token");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-access-token", webToken);
    const params = new URLSearchParams({
      skip: currentLimitOfset.skip,
      limit: currentLimitOfset.limit,
    });

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(`${API_URL}api/profile/payment?${params}`, requestOptions)
      .then(async (response) => {
        if (response.status === 200 || response.ok) {
          // console.log("aqws"); // Tek seferliğine çağırılması yeterlidir
          return response.json();
        }
        const responseInJson = await response.json();
        return Promise.reject(responseInJson);
      })
      .then((res) => {
        // console.log('res', res);
        setData(res);
      })
      .catch((error) => console.log("error", error));
  }, [currentLimitOfset]);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let mobileToken = params.get("mobileToken");
    if (mobileToken) {
      window.localStorage.setItem("Token", mobileToken);
    }
    let webToken = window.localStorage.getItem("Token") || mobileToken;

    // if (deviceToken) window.localStorage.setItem("DeviceToken", deviceToken);

    // console.log("token nedir  ", webToken);
    if (webToken) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", webToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(`${API_URL}api/profile/getProfile`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              if (isConnected) disconnect();
              setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }

          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          // console.log("tokenUser", res);

          setLoginResponse({ ...res, userId: res._id, token: webToken });
          getUserWallet(webToken);
        })
        .catch((error) => console.log("error", error));
    }
    if (window.location.pathname === "/") {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, "", "/");
    }
  }, [disconnect, getUserWallet, isConnected]);

  return (
    <AppContext.Provider
      value={{
        loginResponse,
        setLoginResponse,
        loginPressed,
        setLoginPressed,
        coinInfos,
        setCoinInfos,
        walletLogin,
        setWalletLogin,
        setWalletId,
        walletId,
        isWalletEquel,
        setIsWalletEquel,
        AvailableWithdrawAmount,
        setAvailableWithdrawAmount,
        openWallet,
        setOpenWallet,
        text,
        setText,
        data,
        setData,
        currentLimitOfset,
        setcurrentLimitOfset,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
function App() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          // variant="default" // Set default variant
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          preventDuplicate
          autoHideDuration={5000}
        >
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <AppContextWrapper>
              <Router />
              <Footer />
            </AppContextWrapper>
          </ThemeProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
