import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MobileStepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ButtonDetail from "./ButtonDetail";
import GAU from "../assets/GAU.svg";
import close from "../assets/close.svg";
import wallett from "../assets/wallet-02.svg";
import { AppContext } from "../App";
import { makeStyles } from "@material-ui/styles";
import { useWeb3Functions } from "../hooks/useWeb3Functions";

const useStyles = makeStyles((theme) => ({
  spinButton: {
    "&::-webkit-inner-spin-button": {
      appearance: "none",
    },
    "&::-webkit-outer-spin-button": {
      appearance: "none",
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "cyan !important",
    },
  },
}));

const PercentageComp = ({ percentage, onClick, isSelected }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Grid
      item
      sm={2.5}
      xs={2.5}
      sx={{
        backgroundColor: "#1C1E26",
        borderRadius: 4,
        height: matches ? "56px" : "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(isSelected && { border: "2px solid #4E32CE" }),
      }}
    >
      <IconButton onClick={onClick} sx={{ width: "100%", height: "100%" }}>
        <Typography
          sx={{
            color: "#B8B8B9",
            fontSize: "16px",
          }}
        >
          {percentage}
        </Typography>
      </IconButton>
    </Grid>
  );
};

const percentages = [
  { label: "25%", value: 25 },
  { label: "50%", value: 50 },
  { label: "75%", value: 75 },
  { label: "Max", value: 100 },
];

const FirstModalPage = ({
  handleClose,
  onPressContinue,
  styleProps,
  title = "",
  fee = "5",
  MinimumAmount = 250,
  topup = false,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {
    loginResponse,
    walletLogin,
    AvailableWithdrawAmount,
    setAvailableWithdrawAmount,
    text,
    setText,
  } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [selectedPercentageIndex, setSelectedPercentageIndex] = useState(null);
  const classes = useStyles();
  const { myGauBalance } = useWeb3Functions();

  const countries = [
    {
      label: "Connected Wallet",
      color: "#262831",
      address: walletLogin
        ? walletLogin?.length <= 25
          ? walletLogin
          : walletLogin.substr(0, 25) + "..."
        : "",
    },
    // {
    //   label: "Paired Wallet",
    //   address: "720227EYWDHW89EU2EY2HWXYD9Y2EY2HR....",
    // },
    // { label: "Custom Address" },
  ];

  useEffect(() => {
    if (topup) {
    } else {
      setAvailableWithdrawAmount(Number(loginResponse?.wallet)?.toFixed(2));
    }
  }, []);

  useEffect(() => {
    if (text.length === 0) {
      setErrorMessage("");
    } else {
      if (!topup) {
        if (+text < MinimumAmount) {
          setErrorMessage(`*You could send at least ${MinimumAmount} GAU`);
        } else if (+text > AvailableWithdrawAmount) {
          setErrorMessage("*You cannot send more than available amount");
        } else {
          setErrorMessage("");
        }
      } else {
        if (+text < MinimumAmount) {
          setErrorMessage(`*You could send at least ${MinimumAmount} GAU`);
        } else if (+text > myGauBalance) {
          setErrorMessage("*You cannot send more than available amount");
        } else {
          setErrorMessage("");
        }
      }
    }
  }, [myGauBalance, text]);

  useEffect(() => {
    if (topup) {
      if (
        selectedPercentageIndex ||
        typeof selectedPercentageIndex === "number"
      ) {
        setText(
          (myGauBalance * percentages[selectedPercentageIndex].value) / 100
        );
      }
    } else {
      if (
        selectedPercentageIndex ||
        typeof selectedPercentageIndex === "number"
      ) {
        setText(
          (AvailableWithdrawAmount *
            percentages[selectedPercentageIndex].value) /
            100
        );
      }
    }
  }, [myGauBalance, selectedPercentageIndex]);

  return (
    <>
      <Box
        sx={{
          width: matches ? "560px" : "auto",
          height: "auto",
          backgroundColor: "#181920",

          border: "2px solid #262831",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
          ...styleProps?.container,
        }}
      >
        <DialogActions sx={{ pr: 3, py: 2 }}>
          <img
            src={close}
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </DialogActions>
        <Box sx={{ px: matches ? "7%" : "1%" }}>
          <DialogTitle id="alert-dialog-title">
            <Typography
              sx={{
                fontSize: "24px",
                color: "white",
                textAlign: "center",
                pb: "2%",
              }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{
                border: "0.09rem solid #262831",
                borderColor: "#262831",
                borderRadius: 4,
              }}
            >
              <Grid item>
                <List>
                  <ListItem>
                    <Typography sx={{ fontSize: "16px", color: "#959AA2" }}>
                      Available
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <img
                      src={GAU}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                      {topup ? myGauBalance : AvailableWithdrawAmount}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Divider
                orientation="vertical"
                sx={{ border: "1px solid #262831", height: "56px" }}
              />
              <Grid item>
                <List>
                  <ListItem>
                    <Typography sx={{ fontSize: "16px", color: "#959AA2" }}>
                      Minimum
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <img
                      src={GAU}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                      {MinimumAmount}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
              <Divider
                orientation="vertical"
                sx={{ border: "1px solid #262831", height: "56px" }}
              />
              <Grid item>
                {" "}
                <List>
                  <ListItem>
                    <Typography sx={{ fontSize: "16px", color: "#959AA2" }}>
                      Fee
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <img
                      src={GAU}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                      {fee}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogContent>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <form autoComplete="off">
                <TextField
                  sx={{
                    border: "1px solid #262831",
                    borderRadius: 4,
                    backgroundColor: "#0F0F12",
                  }}
                  type="number"
                  error={errorMessage.length > 0}
                  id="outlined-error"
                  // helperText={errorMessage}
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  variant="outlined"
                  fullWidth
                  label="Amount"
                  InputLabelProps={{
                    sx: { color: "#B8B8B9" },
                  }}
                  InputProps={{
                    sx: { color: "#FFFFFF" },
                    classes: {
                      input: classes.spinButton,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ color: "#959AA2", fontSize: "16px" }}>
                          GAU
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>

              {errorMessage && (
                <Typography
                  sx={{
                    color: "#EB2C44",
                    fontSize: "14px",
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogContent sx={{ p: 0, pb: 1 }}>
            <Typography
              sx={{ fontSize: "14px", color: "#4F515C", textAlign: "center" }}
            >
              Or choose
            </Typography>
          </DialogContent>
          <DialogContent sx={{ py: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              {React.Children.toArray(
                percentages.map(({ label }, index) => (
                  <PercentageComp
                    percentage={label}
                    isSelected={index === selectedPercentageIndex}
                    onClick={() => {
                      setSelectedPercentageIndex(index);
                    }}
                  />
                ))
              )}
            </Grid>
          </DialogContent>

          {!topup && (
            <DialogContent>
              <Autocomplete
                id="country-select-demo"
                classes={{
                  option: styles.option,
                }}
                defaultValue={countries[0]}
                sx={{
                  width: "fullWidth",
                  color: "white",
                  "	.MuiAutocomplete-popupIndicator": { color: "#B8B8B9" },
                  ".MuiAutocomplete-hasPopupIcon": {
                    color: "#B8B8B9",
                  },
                }}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => {
                  const { label, color } = option;
                  return (
                    // <div {...props} style={{ backgroundColor: color}}>
                    //   {label}
                    // </div>
                    <Box
                      component="li"
                      style={{
                        backgroundColor: color,
                        color: "white",
                        fontSize: "14px",
                        justifyContent: "space-evenly",
                        height: "45px",
                      }}
                      // sx={{
                      //   backgroundColor: color,
                      //   '& > img': {
                      //     mr: 2,
                      //     flexShrink: 0,
                      //   },
                      // }}
                      {...props}
                    >
                      <img
                        src={wallett}
                        style={{
                          color: "#FFFFFF",
                          width: "20px",
                          height: "18px",
                        }}
                      />
                      {label} {option.address}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      border: "1px solid #262831",
                      borderRadius: 4,

                      backgroundColor: "#0F0F12",
                    }}
                    {...params}
                    label="Send to"
                    InputLabelProps={{
                      sx: { color: "#B8B8B9" },
                    }}
                    inputProps={{
                      sx: { color: "#FFFFFF", fontSize: "12px" },
                      ...params.inputProps,
                      disableUnderline: true,
                      readOnly: true,
                    }}
                  />
                )}
              />
            </DialogContent>
          )}
          <DialogActions
            sx={{ mt: topup ? "7%" : "5%", mb: "2%", justifyContent: "center" }}
          >
            <ButtonDetail
              onClick={() => onPressContinue(text)}
              disabled={errorMessage || text.length === 0}
              text="Continue"
              jc="center"
              width="295px"
              color="#FFFFFF"
              bgg="linear-gradient(95.41deg, #7021BF 0%, #4C32CE 100%)"
            />
          </DialogActions>
          <DialogContent sx={{ p: 0 }}>
            <MobileStepper
              variant="dots"
              steps={2}
              position="static"
              activeStep={0}
              sx={{
                ".MuiMobileStepper-dotActive": { backgroundColor: "#FFFFFF" },
                backgroundColor: "#181920",
                justifyContent: "center",
                color: "white",
              }}
            />
          </DialogContent>
        </Box>
      </Box>
    </>
  );
};
export default FirstModalPage;
