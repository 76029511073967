import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const ButtonComp = (props) => {
  const {
    text,
    bg = '#262831',
    onClick,
    jc = 'center',
    textColor = '#FFFFFF',
    wd = '160px',
  } = props;
  return (
    <>
      <Grid container direction="row" justifyContent={jc} alignItems="center">
        <Button
          variant="contained"
          onClick={onClick}
          sx={{
            backgroundColor: bg,
            textTransform: 'none',
            width: wd,
            height: '48px',
            borderRadius: 2,
            color: textColor,
          }}
        >
          {text}
        </Button>
      </Grid>
    </>
  );
};

export default ButtonComp;
