import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  MobileStepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useContext, useId, useMemo } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ButtonDetail from "./ButtonDetail";
import close from "../assets/close.svg";
import GAU from "../assets/GAU.svg";
import ArrowLeft from "../assets/ArrowLeft.svg";
import { API_URL } from "../config/default";
import { AppContext } from "../App";
import { auth } from "../services/firebase";
import { useWeb3Functions } from "../hooks/useWeb3Functions";
import { guidGeneretor } from "../helper/helpers";

const SecondModalPage = ({
  handleClose,
  summaryState,
  setPageIndex,
  styleProps,
  textt = "",
  topup = false,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { loginResponse, walletLogin, setWalletLogin, setLoginResponse } =
    useContext(AppContext);
  const { useSendTransaction, isConnected } = useWeb3Functions();
  const { sendTransaction } = useSendTransaction();
  const { open: openWeb3 } = useWeb3Modal();

  const signOutCb = useCallback(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      auth.signOut().then(() => {
        if (!isConnected) {
          openWeb3();
        }
        setLoginResponse(null);
        setWalletLogin(null);
        localStorage.clear();
      });
    }
  }, [isConnected, openWeb3, setLoginResponse, setWalletLogin]);

  const withDraws = useCallback(
    (guid) => {
      // let deviceToken = window.localStorage.getItem("DeviceToken");
      if (loginResponse?.token && walletLogin) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-access-token", loginResponse?.token);
        var raw = JSON.stringify({
          publicAddress: walletLogin,
          amount: summaryState,
          guid,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        fetch(`${API_URL}api/profile/makeWithdrawRequest`, requestOptions)
          .then(async (response) => {
            if (response.status === 200 || response.ok) {
              return Promise.resolve(response.json()); // This will end up in SUCCESS part
            } else if (response.status === 401) {
              signOutCb();
            }
            const responseInJson = await Promise.resolve(response.json());
            return Promise.reject(responseInJson);
          })
          .then((res) => {
            setPageIndex((prevActiveStep) => prevActiveStep + 1);
          })
          .catch((error) => console.log("error", error));
      }
    },
    [loginResponse?.token, setPageIndex, signOutCb, summaryState, walletLogin]
  );

  const handleNext = () => {
    if (topup) {
      console.log("top up atıyorum", summaryState);
      setPageIndex((prevActiveStep) => prevActiveStep + 1);
      sendTransaction(
        summaryState,
        () => setPageIndex((prevActiveStep) => prevActiveStep + 1),
        () => {
          handleClose?.();
        }
      );
    } else {
      const guid = guidGeneretor();
      withDraws(guid);
    }
  };

  const handleBack = () => {
    setPageIndex((prevActiveStep) => prevActiveStep - 1);
  };
  const Fee = topup ? 0 : 5;
  const Summary = useMemo(() => {
    return +summaryState - Fee;
  }, [Fee, summaryState]);
  return (
    <>
      <Box
        sx={{
          width: matches ? "560px" : "auto",
          height: "80%",
          backgroundColor: "#181920",

          border: "2px solid #262831",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
          ...styleProps?.container,
        }}
      >
        <DialogActions sx={{ px: 3, py: 2, justifyContent: "space-between" }}>
          <img
            src={ArrowLeft}
            alt="left-arrow-icon"
            onClick={handleBack}
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          <img
            src={close}
            alt="close-icon"
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </DialogActions>
        <Box sx={{ px: matches ? "7%" : "1%" }}>
          <DialogTitle id="alert-dialog-title">
            <Typography
              sx={{
                fontSize: "24px",
                color: "white",
                textAlign: "center",
                pb: "1%",
              }}
            >
              Summary
            </Typography>
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center", py: 0 }}>
            <img
              src={GAU}
              alt="gau-icon"
              style={{
                width: "50px",
                height: "50px",
              }}
            />
          </DialogActions>
          <DialogContent sx={{ py: 0 }}>
            <List>
              <ListItem sx={{ justifyContent: "center" }} disableGutters>
                <Typography
                  sx={{ fontSize: "32px", color: "#FFFFFF", fontWeight: "700" }}
                >
                  {Summary}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    textAlign: "end",
                    alignItems: "flex-end",
                    mt: "2%",
                    pl: "1%",
                  }}
                >
                  GAU
                </Typography>
              </ListItem>
            </List>
          </DialogContent>
          <DialogContent sx={{ px: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: "#262831", borderRadius: 3, px: "2%" }}
            >
              <Grid item>
                <Typography
                  sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: 600 }}
                >
                  Amount
                </Typography>
              </Grid>
              <Grid item>
                <List>
                  <ListItem sx={{ justifyContent: "center" }}>
                    <img
                      src={GAU}
                      alt="gau-icon"
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#FFFFFF",
                        fontWeight: "700",
                      }}
                    >
                      {summaryState}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: "#262831",
                borderRadius: 3,
                px: "2%",

                my: "2%",
              }}
            >
              <Grid item>
                <Typography
                  sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: 600 }}
                >
                  Fee
                </Typography>
              </Grid>
              <Grid item>
                <List>
                  <ListItem sx={{ justifyContent: "center" }}>
                    <img
                      src={GAU}
                      alt="gau-icon"
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#FFFFFF",
                        fontWeight: "700",
                      }}
                    >
                      {Fee}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: "#262831", borderRadius: 3, px: "2%" }}
            >
              <Grid item>
                <Typography
                  sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: 600 }}
                >
                  Source
                </Typography>
              </Grid>
              <Grid item sx={{ pr: matches ? "3%" : "5%" }}>
                <List>
                  <ListItem sx={{ justifyContent: "end", p: 0 }}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#FFFFFF",
                        fontWeight: "700",
                      }}
                    >
                      GamerArena Wallet
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ justifyContent: "end", p: 0 }}>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "#FFFFFF",
                        fontWeight: "500",
                      }}
                    >
                      {walletLogin}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ m: "5%", justifyContent: "center" }}>
            <ButtonDetail
              onClick={handleNext}
              text={textt}
              jc="center"
              width="295px"
              color="#FFFFFF"
              bgg="linear-gradient(95.41deg, #7021BF 0%, #4C32CE 100%)"
            />
          </DialogActions>
          <DialogContent sx={{ p: 0 }}>
            <MobileStepper
              variant="dots"
              steps={2}
              position="static"
              activeStep={1}
              sx={{
                ".MuiMobileStepper-dotActive": { backgroundColor: "#FFFFFF" },
                backgroundColor: "#181920",
                justifyContent: "center",
                color: "white",
              }}
            />
          </DialogContent>
        </Box>
      </Box>
    </>
  );
};

export default SecondModalPage;
