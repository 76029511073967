import { Grid, Link, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import twitter from '../../assets/twitter.svg';
import instagram from '../../assets/instagram.svg';
import discord from '../../assets/discord.svg';
import facebook from '../../assets/facebook.svg';

const FooterThre = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid
      container
      direction={'row'}
      justifyContent="center"
      alignItems={matches ? 'flex-start' : 'center'}
      spacing={3}
      sx={{ mt: matches ? 0 : '2%' }}
    >
      <Grid item>
        <Link
          href="https://www.instagram.com/gamerarena_com/"
          target="_blank"
          rel="noopener"
        >
          <img
            src={instagram}
            style={{
              color: '#b8b8ba',
              width: '24px',
              height: '24px',
            }}
          />
        </Link>
      </Grid>
      <Grid item>
        <Link
          href="https://www.facebook.com/gamerarenacom/"
          target="_blank"
          rel="noopener"
        >
          {' '}
          <img
            src={facebook}
            style={{
              color: '#b8b8ba',
              width: '24px',
              height: '24px',
            }}
          />
        </Link>
      </Grid>
      <Grid item>
        <Link
          href="https://twitter.com/gamerarena_com"
          target="_blank"
          rel="noopener"
        >
          {' '}
          <img
            src={twitter}
            style={{
              color: '#b8b8ba',
              width: '24px',
              height: '24px',
            }}
          />
        </Link>
      </Grid>
      <Grid item>
        <Link
          href="https://discord.com/invite/gamer-arena-652064236083871745"
          target="_blank"
          rel="noopener"
        >
          {' '}
          <img
            src={discord}
            style={{
              color: '#b8b8ba',
              width: '24px',
              height: '24px',
            }}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default FooterThre;
