import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import close from "../assets/close.svg";
import gauabi from "../ABI/GAUABI.json";
import Ellipse from "../assets/Ellipse.svg";

const TopupGAPage = ({
  handleClose,
  styleProps,
  setPageIndex,
  summaryState,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <>
      <Box
        sx={{
          width: matches ? "560px" : "auto",
          height: "80%",
          backgroundColor: "#181920",
          px: matches ? "7%" : "1%",
          border: "2px solid #262831",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
          ...styleProps?.container,
        }}
      >
        <DialogActions sx={{ pr: 2, py: 2 }}>
          <img
            src={close}
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </DialogActions>
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{
              fontSize: "25px",
              color: "white",
              textAlign: "center",
              pb: "5%",
            }}
          >
            Top-up Gamer Arena
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <img
            src={Ellipse}
            style={{
              width: "161px",
              height: "161px",
            }}
          />
        </DialogContent>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Your approval is awaited for the transfer process to be completed.
          </Typography>
        </DialogContent>
        <DialogContent
          sx={{ display: "flex", justifyContent: "center", py: 0, mb: "20%" }}
        >
          <CircularProgress color="inherit" />
        </DialogContent>
      </Box>
    </>
  );
};

export default TopupGAPage;
