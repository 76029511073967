import { Grid, Typography } from '@mui/material';
import React from 'react';

const TitleComp = (props) => {
  const { title = '' } = props;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mb: '1%' }}
      >
        {' '}
        <Typography sx={{ fontSize: '18px', color: 'white' }}>
          {title}
        </Typography>
      </Grid>
    </>
  );
};

export default TitleComp;
