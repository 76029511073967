import React from "react";

const TournamentRulesPage = () => {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh2\n\t{margin-top:.25in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\nh4\n\t{margin-top:14.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:12.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#666666;\n\tfont-weight:normal;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:26.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n',
        }}
      />
      <div style={{ margin: "12px 16px" }} className="WordSection1">
        <p className="MsoTitle">
          <a name="_mgagraakmr0" />
          <span lang="EN" style={{ fontSize: "23.0pt", lineHeight: "115%" }}>
            GAU Arena Tournament and Duel Rules
          </span>
        </p>
        <p className="MsoNormal">
          <b>
            <span lang="EN" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
              Effective Date:
            </span>
          </b>
          <span lang="EN" style={{ fontSize: "13.0pt", lineHeight: "115%" }}>
            January 15th, 2024
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN">&nbsp;</span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_hqeh1mwgg9cr" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Introduction</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            Welcome to GAU Arena, the premier platform for competitive gaming.
            These rules govern your participation in the various duels and
            tournaments on our platform. By engaging in these duels and
            tournaments, you agree to abide by these rules. No payment or
            purchase will directly increase a player’s chances of winning, all
            of the games within the app are skill-based games where all players
            compete equally. By entering a duel or tournament, all players agree
            to abide by and be bound by these official rules and consent to the
            use of their personal information to administer the duel or
            tournament.
          </span>
        </p>
        <h2
          style={{
            marginTop: ".25in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: ".5in",
            textIndent: "-.5in",
          }}
        >
          <a name="_co055zw6ts6y" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">General Participation</span>
        </h2>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_n85o1mhxwxsk" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            2.1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span lang="EN" style={{ color: "white" }}>
              Duels:
            </span>
          </b>
          <b>
            <span
              lang="EN"
              style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
            >
              {" "}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
          >
            Players are matched according to their rank. Both players pay an
            entry fee from their wallet, with the winner who gets a higher score
            than the opponent, receiving 90% of the total combined entry fee.
            Gamer Arena retains a 10% commission from each player's entry fee.
          </span>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "4.0pt",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_a34wr6ylgdyg" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            2.2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN"
              style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
            >
              Tournaments:{" "}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
          >
            Players can participate with GAU tokens. Rankings and potential GAU
            token earnings based on the player’s highest score are displayed
            within the app.
          </span>
        </h4>
        <p className="MsoNormal" style={{ marginLeft: "1.0in" }}>
          <span lang="EN">&nbsp;</span>
        </p>
        <h2
          style={{
            marginTop: ".25in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: ".5in",
            textIndent: "-.5in",
          }}
        >
          <a name="_uw07hyboqsc3" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>3.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Earning and Using GAU token</span>
        </h2>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_h4xcfn6jt7bb" />
          <span
            lang="EN"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
          >
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            3.1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN"
              style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
            >
              Wallet Connect:{" "}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
          >
            Players can deposit GAU tokens with connecting their wallets.
          </span>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "4.0pt",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_bdbe05fztv58" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            3.2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              lang="EN"
              style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
            >
              Referrals:{" "}
            </span>
          </b>
          <span
            lang="EN"
            style={{ fontSize: "11.0pt", lineHeight: "115%", color: "white" }}
          >
            Player
          </span>
          <span lang="EN" style={{ color: "white" }}>
            s can earn GAU tokens by inviting friends to GAU Arena.
          </span>
        </h4>
        <p className="MsoNormal" style={{ marginLeft: "1.0in" }}>
          <span lang="EN">&nbsp;</span>
        </p>
        <h2
          style={{
            marginTop: ".25in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: ".5in",
            textIndent: "-.5in",
          }}
        >
          <a name="_qsomcbknrt1c" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>4.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Participant Eligibility</span>
        </h2>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_x5mjue2xd514" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            4.1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN" style={{ color: "white" }}>
            The Tournaments and Duels are open to all registered users of GAU
            Arena, legal residents of all countries where the Gamer Arena app is
            distributed on Google Play Store and AppStore. Participants must
            have reached the age of +12 or the applicable age of majority
            (whichever is higher) at the time of entry. Participation is also
            subject to local regulations, and the tournaments and duels might
            not be available where local regulation prevents skill-based gaming
            participation in paid events with prizes.
          </span>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_mh6lq2n9gbhl" />
          <b>
            <span lang="EN" style={{ color: "white" }}>
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              4.2.
              <span style={{ font: '7.0pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
          </b>
          <b>
            <span lang="EN" style={{ color: "white" }}>
              Exclusions:
            </span>
          </b>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.5in",
            textIndent: "-1.5in",
          }}
        >
          <a name="_n412ckhbqvia" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            4.2.1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN" style={{ color: "white" }}>
            Participants are not eligible to enter or win if they are an
            employee, officer, director, representative, or agent of GAU Arena,
            its parent, subsidiaries, related or affiliated companies, or, if
            applicable, any of their respective advertising or promotion
            agencies, or any other company or individual engaged in the
            provision of goods or services related to this event.
          </span>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "4.0pt",
            marginLeft: "1.5in",
            textIndent: "-1.5in",
          }}
        >
          <a name="_6pepksbzhauk" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            4.2.2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN" style={{ color: "white" }}>
            Additionally, a member of the immediate family (parent, child,
            sibling, spouse, grandparent, grandchild, cousin, aunt, or uncle,
            whether related through blood, marriage, common law, civil union, or
            adoption, regardless of where they live) or household (whether
            related or not) of any of the above individuals, directly or
            indirectly related, is also ineligible.
          </span>
        </h4>
        <p className="MsoNormal" style={{ marginLeft: "1.5in" }}>
          <span lang="EN">&nbsp;</span>
        </p>
        <h2
          style={{
            marginTop: ".25in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: ".5in",
            textIndent: "-.5in",
          }}
        >
          <a name="_sg89f7dmnix9" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>5.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Apple Disclaimer</span>
        </h2>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_23g4van3hdyr" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            5.1.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN" style={{ color: "white" }}>
            5.1 Apple, Inc. is not a sponsor of, or responsible for conducting,
            the GAU Arena tournaments or duels.
          </span>
        </h4>
        <h4
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "4.0pt",
            marginLeft: "1.0in",
            textIndent: "-1.0in",
          }}
        >
          <a name="_e1ia3gsmtjr2" />
          <span lang="EN" style={{ color: "white" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            5.2.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN" style={{ color: "white" }}>
            5.2 All participants must abide by the official rules, which are
            available within the GAU Arena app at all times.
          </span>
        </h4>
        <p className="MsoNormal">
          <span lang="EN">&nbsp;</span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_2jvkdgxcwnn9" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>6.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Right to Suspend, Modify, and Terminate</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            The GAU Arena management reserves the right, in its sole discretion,
            to suspend, modify, or terminate any tournament or duel at any time
            if it is not capable of running as planned for reasons including
            computer viruses, bugs, unauthorized intervention, fraud, technical
            failures, or any other causes which could corrupt or affect the
            administration, security, fairness, or proper conduct of the
            tournament or duel. GAU Arena may suspend, modify, or terminate the
            event and disqualify any individual responsible for such
            interruptions.
          </span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_bdffa2v5nbby" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>7.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">
            Confidentiality, Publicity, and Intellectual Property Rights
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            Participants must maintain the confidentiality of any information
            they possess related to GAU Arena, its operations, or the duels and
            tournaments. GAU Arena retains all intellectual property rights
            associated with publicity, marketing, and promotional materials.
            Participants must not publicize their involvement or winnings
            without GAU Arena's written consent. By entering a duel or
            tournament, participants grant GAU Arena a perpetual, royalty-free
            license to use any submitted content, and they confirm that such
            content does not infringe on the rights of third parties.
          </span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_y95uyb71q6op" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>8.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Limitation of Liability and Release</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            GAU Arena and its partners are not responsible for any technical
            malfunctions, disruptions, or damages that may occur during the
            course of a tournament or duel. Participants agree to release and
            hold harmless GAU Arena, its affiliates, and partners from any
            claims or liabilities that may arise from participation. Any attempt
            to tamper with the operation of a tournament or duel will be
            prosecuted to the fullest extent of the law.
          </span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_4gpciwt4sp8d" />
          <span lang="EN">
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>9.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Privacy and Public Release</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            By accepting a reward, players allow GAU Arena to use their names,
            images, and other related content for promotional purposes. GAU
            Arena will handle all personal information in accordance with its
            Privacy Policy.
          </span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_yb3w7mwadd6m" />
          <span lang="EN">
            10.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">General Conditions</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            GAU Arena reserves the right to modify these rules at any time,
            subject to applicable law. Questions regarding these rules will be
            governed by the laws of [Country, e.g., "Turkey"]. This promotion
            is not affiliated with Facebook, Google, or Apple.
          </span>
        </p>
        <h2 style={{ marginLeft: ".5in", textIndent: "-.5in" }}>
          <a name="_c2ihppd27e91" />
          <span lang="EN">
            11.
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Questions &amp; Contact</span>
        </h2>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            For questions or comments about these rules, please contact us at{" "}
            <a href="mailto:info@gamerarena.com">
              <span style={{ color: "#1155CC" }}>info@gamerarena.com</span>
            </a>
            .
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">&nbsp;</span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <span lang="EN">
            Thank you for understanding our Tournament and Duel Rules.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <b>
            <span lang="EN">&nbsp;</span>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
          <b>
            <span lang="EN">Links:</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: ".5in", textIndent: "-.25in" }}
        >
          <span lang="EN" style={{ fontFamily: "Roboto", color: "white" }}>
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">
            <a href="https://gamerarena.com/terms-of-service">
              <span style={{ color: "#1155CC" }}>Terms of Service</span>
            </a>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: ".5in", textIndent: "-.25in" }}
        >
          <span lang="EN" style={{ fontFamily: "Roboto", color: "white" }}>
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">
            <a href="https://gamerarena.com/privacy-policy">
              <span style={{ color: "#1155CC" }}>Privacy Policy</span>
            </a>
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: ".5in", textIndent: "-.25in" }}
        >
          <span lang="EN" style={{ fontFamily: "Roboto", color: "white" }}>
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Tournament and Duel Rules</span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: ".5in", textIndent: "-.25in" }}
        >
          <span lang="EN" style={{ fontFamily: "Roboto", color: "white" }}>
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">&nbsp;</span>
        </p>
        <p
          className="MsoNormal"
          style={{ marginLeft: ".5in", textIndent: "-.25in" }}
        >
          <span lang="EN" style={{ fontFamily: "Roboto", color: "white" }}>
            ●
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span lang="EN">Gamer Arena © 2024</span>
        </p>
        <p className="MsoNormal">
          <span lang="EN">&nbsp;</span>
        </p>
        <p className="MsoNormal">
          <span lang="EN">&nbsp;</span>
        </p>
      </div>
    </>
  );
};

export default TournamentRulesPage;
