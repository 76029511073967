import { Navigate, useRoutes } from "react-router-dom";
import DrawerComp from "./components/DrawerComp";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TournamentRulesPage from "./pages/TournamentRulesPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
// layouts

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <DrawerComp />,
    },
    {
      path: "/tournament-duel-rules",
      element: <TournamentRulesPage />,
    },
    {
      path: "/terms-of-service",
      element: <TermsOfServicePage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
