import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { API_URL } from "../config/default";
import { TableFooter, TablePagination, Tooltip } from "@mui/material";
import { AppContext } from "../App";

const TableWithdraws = () => {
  const { data, setData, setcurrentLimitOfset, currentLimitOfset } =
    useContext(AppContext);
  const dynamicRowsPerPage = Math.min(10, data.length);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isDisabled, setIsDisabled] = useState(false);

  // const handleChangePage = (event, newPage) => {
  //     if (data?.length > 0) {
  //       setPage(newPage);
  //       setcurrentLimitOfset({ skip: newPage !== 0 ? newPage * rowsPerPage : 0, limit: rowsPerPage });
  //     } else {
  //       setcurrentLimitOfset({ skip: newPage !== 0 ? (newPage - 1) * rowsPerPage : 0, limit: rowsPerPage });
  //     }
  //   };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentLimitOfset({ skip: 0, limit: event.target.value });
  };
  function defaultLabelDisplayedRows({ from, to, count }) {
    return `${from}–${to}`;
  }
  const setTableHeight = () => {
    const tableContainer = document.getElementById("withdraws-table-container");
    if (tableContainer) {
      const newHeight =
        window.innerHeight - tableContainer.getBoundingClientRect().top - 20;
      tableContainer.style.minHeight = `${newHeight}px`;
    }
  };
  useEffect(() => {
    setTableHeight();
  }, [page, rowsPerPage]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate.replace(",", "").replace(/[/]/g, "-");
  }
  function createData(publicAddress, amount, date, transaction, status) {
    return { publicAddress, amount, date, transaction, status };
  }

  const rows = data.map((item) =>
    createData(
      item.publicAddress,
      item.amount.$numberDecimal,
      item.date,
      item.transaction,
      item.status
    )
  );
  function getStatusText(status) {
    switch (status) {
      case 0:
        return "İPTAL";
      case 1:
        return "TALEP ALINDI";
      case 2:
        return "İŞLEMDE";
      case 3:
        return "TAMAMLANDI";
      case 4:
        return "SİSTEM TARAFINDAN İPTAL";
      case 5:
        return "OZANPAY PENDING";
      case 6:
        return "ÖDEME HATASI";
      case 7:
        return "SERVICE PENDING";
      case 8:
        return "BAŞARILI İŞLEM";
      case 9:
        return "İNCELEMEDE";
      default:
        return "";
    }
  }
  const shouldChangePage = (newPage) => {
    // Veri sayısı 10'dan fazlaysa her zaman sayfa değişikliği yap
    if (data.length > 10) {
      return true;
    }

    // Veri sayısı 10 veya daha azsa ve yeni sayfa numarası 0'dan farklıysa sayfa değişikliği yap
    return newPage !== 0;
  };
  const handleChangePage = (event, newPage) => {
    if (shouldChangePage(newPage)) {
      setIsDisabled(false);
      setcurrentLimitOfset({
        skip: currentLimitOfset?.skip + newPage * rowsPerPage,
        limit: currentLimitOfset?.limit,
      });
      setPage(newPage);
    }
  };
  const isDataLessThanOrEqualTo10 = data.length <= 10;
  return (
    <TableContainer
      id="withdraws-table-container"
      component={Paper}
      sx={{ margin: "3%", maxWidth: "80%", overflowX: "auto", height: "45vh" }}
    >
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Public Address</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Transaction</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Tooltip title={row.publicAddress}>
                  <span>
                    {row.publicAddress?.substring(0, 6) +
                      "..." +
                      row.publicAddress?.substring(
                        row.publicAddress?.length - 4
                      )}
                  </span>
                </Tooltip>
              </TableCell>
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center">{formatDate(row.date)}</TableCell>
              <TableCell align="center">{row.transaction}</TableCell>
              <TableCell align="center">{getStatusText(row.status)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={100000}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={defaultLabelDisplayedRows}
              nextIconButtonProps={{
                onClick: () => {
                  if (shouldChangePage(page + 1)) {
                    setIsDisabled(false);
                    setcurrentLimitOfset({
                      skip: currentLimitOfset?.skip + (page + 1) * rowsPerPage,
                      limit: currentLimitOfset?.limit,
                    });
                    setPage(page + 1);
                  }
                },
                disabled: isDataLessThanOrEqualTo10,
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default TableWithdraws;
