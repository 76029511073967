import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import GAlogo from '../../assets/GAlogo.svg';

const FooterOne = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid
      container
      direction="column"
      alignItems={matches ? 'flex-start' : 'center'}
      spacing={4}
      sx={{ px: '5%' }}
    >
      <Grid item sx={{ mt: matches ? 0 : '1%' }}>
        <img
          src={GAlogo}
          style={{
            width: '144px',
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontSize: '12px',
            color: '#FFFFFFB2',
            textAlign: !matches && 'center',
          }}
        >
          Gamer Arena; Electronic Arts, Activision Blizzard, Valve Corporation,
          Miniclip, Epic Games, Blizzard Entertainment, Microsoft, Xbox, Sony ya
          da Playstation tarafından desteklenmemektedir, bu şirketlerle doğrudan
          bağlantılı ya da iş birliği içinde değildir. Tüm içerikler, oyun
          başlıkları, ticari isimler veya takdimler, markalar, görseller ve
          imgeler ilgili sahip firmaların marka ya da telif ürünüdür.
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: '10px', color: '#FFFFFFB2' }}>
          © 2024 Gamer Arena
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterOne;
