import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import vektor from "../assets/Vector.svg";
import GAU from "../assets/GAU.svg";
import ButtonDetail from "./ButtonDetail";
import { AppContext } from "../App";
import ModalDisconnect from "./ModalDisconnect";
import { useWeb3Functions } from "../hooks/useWeb3Functions";

const CardDetailWallet = ({ hg }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const buttonRes = useMediaQuery("(min-width: 800px) and (max-width: 1180px)");
  const { myGauBalance } = useWeb3Functions();
  const { walletLogin } = useContext(AppContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    window.localStorage.setItem("wallet", walletLogin);
  }, [walletLogin]);

  return (
    <Card
      sx={{
        background: " linear-gradient(118.02deg, #8001FF 0%, #64F7E5 121.76%)",
        borderRadius: 6,
        height: matches ? "200px" : "255px",
      }}
    >
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: "1%" }}
        >
          <Grid item justifyContent="flex-start">
            <List>
              <ListItem>
                <Typography
                  sx={{
                    opacity: 0.7,
                    fontSize: "12px",
                    color: "#FFFFFF",
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  {walletLogin.length <= 18
                    ? walletLogin
                    : walletLogin.substr(0, 18) + "..."}
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.7,
                    fontSize: "12px",
                    color: "#FFFFFF",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  {walletLogin}
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item justifyContent="flex-end">
            <Tooltip arrow>
              <img
                src={vektor}
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent sx={{ p: 0, mt: "2%" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: "1%" }}
        >
          <Grid item justifyContent="flex-start">
            <List>
              <ListItem>
                <img
                  src={GAU}
                  style={{
                    width: "49.74px",
                    height: "50px",
                  }}
                />
                <Typography sx={{ fontSize: "24px", color: "#FFFFFF", px: 1 }}>
                  {myGauBalance ?? "-"}
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", color: "#FFFFFF", mt: "3%" }}
                >
                  GAU
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            sx={{ pl: matches ? 0 : "5%" }}
            justifyContent={matches ? "flex-end" : "center"}
          >
            <List>
              <ListItem sx={{ p: 0, justifyContent: "flex-end" }}>
                <ButtonDetail
                  text="Disconnect Wallet"
                  color="white"
                  border={1.5}
                  onClick={handleClickOpen}
                  width={matches ? "160px" : "143px"}
                />{" "}
                {buttonRes && (
                  <Grid
                    sx={{
                      width: "160px",
                      color: "red",
                      height: "48px",
                    }}
                  />
                )}
              </ListItem>
            </List>
            <ModalDisconnect open={open} setOpen={setOpen} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardDetailWallet;
