import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import FooterOne from './FooterOne';
import FooterTwo from './FooterTwo';
import FooterThre from './FooterThre';

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Divider sx={{ color: 'white' }} />

      <Grid
        container
        sx={{ height: '280px', backgroundColor: '#0F0F12', p: '2%' }}
        justifyContent="space-between"
      >
        {matches ? (
          <>
            <Grid item sm={5} xs={12}>
              <FooterOne />
            </Grid>
            <Grid item sm={4.5} xs={12}>
              <FooterTwo />
            </Grid>
            <Grid item sm={2.5} xs={12}>
              <FooterThre />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={5} xs={12}>
              <FooterThre />
            </Grid>
            <Grid item sm={4.5} xs={12}>
              <FooterTwo />
            </Grid>
            <Grid item sm={2.5} xs={12} sx={{ p: '2%' }}>
              <FooterOne />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Footer;
