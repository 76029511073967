import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="Generator" content="Microsoft Word 15 (filtered)" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh2\n\t{margin-top:.25in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh3\n\t{margin-top:16.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:14.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#434343;\n\tfont-weight:normal;}\nh4\n\t{margin-top:14.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:4.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:12.0pt;\n\tfont-family:"Arial",sans-serif;\n\tcolor:#666666;\n\tfont-weight:normal;}\np.MsoTitle, li.MsoTitle, div.MsoTitle\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:3.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:26.0pt;\n\tfont-family:"Arial",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:595.45pt 841.7pt;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n',
        }}
      />
      <div style={{ margin: "12px 16px" }} className="WordSection1">
        <h2 id="clarification-text">Clarification Text</h2>
        <h3 id="gamer-arena-privacy-policy">GAMER ARENA PRIVACY POLICY</h3>
        <p>
          Gamer Arena Teknoloji Hizmetleri A.Ş. (“GAMER ARENA” or the “Company”)
          takes the utmost care to protect privacy and confidentiality and to
          ensure continuous compliance with the General Data Protection
          Regulation. In order to comply with the General Data Protection
          Regulation(“GDPR”), it adopts the fundamental principles stipulated by
          the GDPR (EU) 2016/679 and fulfils its obligations regarding data
          security.
        </p>
        <h4 id="scope-of-the-privacy-policy">Scope of the Privacy Policy</h4>
        <p>
          This Personal Data Processing Policy is presented to the relevant
          persons regarding the following issues:
        </p>
        <ul>
          <li>Methods and legal reasons for collecting personal data,</li>
          <li>Which individual groups&#39; personal data are processed,</li>
          <li>Personal data categories being processed,</li>
          <li>
            In which business processes and for what purposes this personal data
            is used,
          </li>
          <li>
            Technical and administrative measures taken to ensure the security
            of personal data,
          </li>
          <li>
            To whom and for what purpose personal data can be transferred,
          </li>
          <li>
            Personal data retention periods, legal rights of Relevant Persons,
          </li>
          <li>
            How Relevant Persons can change their preferences in terms of
            receiving electronic commercial messages.
          </li>
        </ul>
        <p>
          You acknowledge and agree that we collect and use your personal data
          to allow you to access and use the website and to participate in
          games.
        </p>
        <p>
          We agree to be bound by the General Data Protection Regulation (EU)
          2016/679 when collecting your personal information, as stated in the
          previous clause. We will protect your personal information and respect
          your privacy in accordance with best business practices and applicable
          laws
        </p>
        <p>
          Identity and contact details: 1.Data Controller: Abdullah Özşahin.
          2.Data Protection Officer: Abdullah Özşahin
        </p>
        <h4 id="methods-and-legal-reasons-for-collecting-personal-data">
          Methods and Legal Reasons for Collecting Personal Data
        </h4>
        <p>
          GAMER ARENA collects personal data through membership forms, duelling
          and tournament participation information, and files uploaded by users
          in the Gamer Arena Platform in accordance with the personal data
          processing conditions stipulated in the GDPR and in accordance with
          the legal reasons specified in this Privacy Policy. Your personal data
          will be used by us to allow you to participate in the games and to
          take actions related to your participation in the games. We may also
          use your personal data to inform you about promotions, changes, and
          new services that we think you may find interesting. To receive such
          direct marketing data, you must register for such service by managing
          your settings or contacting support.
        </p>
        <p>
          The Company has the right to contact you, to notify you about new
          products and system improvements, and to reach and send e-mails to you
          for other purposes via the contact addresses specified by you during
          registration, membership, and account creation on the Platform, or
          updated by you later. By accepting these Terms of Use, you accept and
          declare that the Company may engage in the above-mentioned
          communication activities unless otherwise stated. You can unsubscribe
          from the e-mail list with one click through the account settings or by
          using the &quot;I want to unsubscribe&quot; link in the e-mail sent to
          you.
        </p>
        <h4 id="data-categories">Data Categories</h4>
        <h5 id="users-of-the-platform">Users of the Platform</h5>
        <ul>
          <li>
            Identity Information: Name, surname, date of birth, gender, and
            identity card copy
          </li>
          <li>Contact Information: Email address, telephone number,</li>
          <li>
            Customer Information: Membership Information, Unique Membership
            Number, Username, PlayStation Network Account Name, Twitch Channel
            Account Name, Epic Games Account Name, League of Legends Account
            Name, Head Ball Account Name, PUBG Account Name, Photo
          </li>
          <li>
            Customer/Member Transaction Information: Platform credits number,
            credit purchase transaction history, shopping history, game mode,
            win/lose rate, screenshot (in case of conflict), video image (in
            case of conflict), complaint messages,
          </li>
          <li>
            Risk Management Information: IP address, time zone, operating
            system,
          </li>
          <li>
            Transaction Security Information: In-platform messaging, time
            metadata for messages, country information, city information
          </li>
          <li>
            Marketing Information: Cookie records, targeting information,
            reviews showing habits and likes,
          </li>
          <li>
            Legal Action and Compliance Information: The start and end times of
            the service provided, the type of service utilised, the amount of
            data transferred, and the commercial electronic message permission
            given by the Relevant Person in the electronic environment.
          </li>
          <li>
            Marketing Information: E-mail messages sent based on the electronic
            commercial message permission given by the relevant person
          </li>
        </ul>
        <h5 id="website-visitors">Website Visitors</h5>
        <ul>
          <li>Location Information: City of residence, District</li>
          <li>Contact Information: Email address</li>
          <li>Risk Management Information: IP address, session information</li>
          <li>
            Marketing Information: Cookie records, targeting information,
            reviews showing habits and likes
          </li>
          <li>
            Marketing Information: E-mail messages sent based on the electronic
            commercial message permission given by the relevant person
          </li>
          <li>
            Request/Complaint Management: Records of the complaints and/or
            requests conveyed by the relevant person regarding the product or
            service purchased and the transactions made during the evaluation or
            management of these requests.
          </li>
        </ul>
        <h4 id="in-which-business-processes-and-for-what-purposes-personal-data-are-used">
          In Which Business Processes and For What Purposes Personal Data Are
          Used
        </h4>
        <h5 id="platform-users-personal-data">Platform Users’ Personal Data</h5>
        <ul>
          <li>Execution of membership transactions,</li>
          <li>
            Improving the services offered through the GAMER ARENA Platform,
            developing new services, and informing about them,
          </li>
          <li>
            For the Member Customers with commercial electronic message
            approval, analyzing the preferences, likes, and needs of the Member
            Customer for the purpose of performing the Membership Agreement
          </li>
          <li>
            Analyzing the preferences, likes, and needs of visitors with
            commercial electronic message approval and providing special
            promotions, opportunities, and benefits to visitors,
          </li>
          <li>
            Promotion and marketing of applications, goods/products and services
            in line with the preferences and likes of the Member Customer by
            performing remarketing, targeting, profiling, and analysis,
          </li>
          <li>Resolving user problems and complaints,</li>
          <li>Improving the User experience on the Platform,</li>
          <li>Follow-up of accounting and purchasing transactions,</li>
          <li>Legal processes and compliance with legislation,</li>
          <li>
            Answering information requests from administrative and judicial
            authorities,
          </li>
          <li>
            Ensuring information and transaction security and preventing
            malicious use,
          </li>
          <li>
            Making necessary arrangements in order to ensure that the processed
            data is up-to-date and correct.
          </li>
        </ul>
        <h5 id="website-visitors">Website Visitors</h5>
        <ul>
          <li>
            Improving the services offered on the website, developing new
            services, and informing about it,
          </li>
          <li>
            Analyzing the preferences, likes, and needs of visitors with
            commercial electronic message approval and providing special
            promotions, opportunities, and benefits to visitors,
          </li>
          <li>
            Promotion and marketing of applications, goods/products, and
            services in line with the Customer&#39;s preferences and likes
            through remarketing, targeting, profiling, and analysis,
          </li>
          <li>Resolving visitor problems and complaints,</li>
          <li>Legal processes and compliance with legislation,</li>
          <li>
            Answering information requests from administrative and judicial
            authorities,
          </li>
          <li>
            Ensuring information and transaction security and preventing
            malicious use,
          </li>
          <li>
            Making the necessary arrangements in order to ensure that the
            processed data is up-to-date and correct,
          </li>
          <li>Fulfillment of legal obligations</li>
        </ul>
        <h4 id="technical-and-administrative-measures-taken-to-ensure-the-security-of-personal-data">
          Technical and Administrative Measures Taken to Ensure the Security of
          Personal Data
        </h4>
        <ul>
          <li>
            GAMER ARENA undertakes to take all necessary technical and
            administrative measures and to exercise due diligence to ensure the
            security of your personal data.
          </li>
          <li>
            GAMER ARENA takes the necessary measures to prevent unauthorized
            access, misuse, unlawful processing, disclosure, alteration, or
            destruction of personal data. GAMER ARENA uses generally accepted
            security technology standards, such as firewalls and encryption,
            when processing personal data.
          </li>
          <li>
            Regarding the prevention of unlawful access to the personal data
            processed by GAMER ARENA, the prevention of unlawful processing of
            these data, and the protection of personal data:
          </li>
          <li>
            All areas of the website from which personal data are taken are
            protected with SSL,
          </li>
          <li>
            Creates and implements access authorization and control matrices for
            its employees,
          </li>
          <li>
            It ensures that personal data on paper media is kept in locked
            cabinets and only accessed by authorized persons.
          </li>
          <li>
            Personal data processed through cookies belonging to third parties
            from which service is received is deleted from the systems of third
            parties if the membership is terminated.
          </li>
          <li>
            Despite GAMER ARENA taking the necessary information security
            measures, in the event that personal data is damaged or falls into
            the hands of unauthorized third parties as a result of an attack on
            the platforms operated by GAMER ARENA or the GAMER ARENA system,
            GAMER ARENA immediately informs the relevant persons and the
            Personal Data Protection Board and takes the necessary actions.
          </li>
        </ul>
        <h4 id="transfer-of-personal-data">Transfer of Personal Data</h4>
        <p>
          GAMER ARENA transfers personal data to third parties only for the
          purposes specified in this Privacy Policy and in accordance with
          Articles 44 and 45 of the GDPR. Personal data collected through
          website usage preferences and browsing history, through entering duels
          and tournaments and their results, and through membership forms and
          other forms is shared with our domestic/international business
          partners for the purpose of profiling and communicating in line with
          the likes and preferences of the relevant persons. Personal data
          transfers within this scope are carried out through the secure
          environment and channels provided by the relevant third party.
          Depending on the content and scope of the service received from third
          parties, the transfer is made using masked personal data in all cases
          where there is no need to transfer personal data. The personal data
          subject to domestic and international transfer, which we mentioned
          above, is also legally protected by the provisions in accordance with
          the GDPR in our contracts, taking into account whether the other party
          of the legal relationship is a data controller or a data processor, as
          well as the technical measures to ensure their security. While
          transferring personal information to countries other than Turkey
          during the sharing of information as stated above, it is ensured that
          the data is transferred in accordance with this policy and as
          permitted by the applicable law on data protection. Your personal data
          will not be given to third parties unless necessary to process your
          requests regarding your participation in the games unless specifically
          permitted by you or unless required by law. As GA&#39;s business
          partners, suppliers, or service providers may be responsible for the
          overall or certain parts of the operation of the website, personal
          data may be transmitted to them. Relevant authorities and GA
          employees, especially the customer support line, payments team, and
          other employees, may access your personal data to perform their duties
          and provide you with assistance and services. You are deemed to have
          accepted such disclosures.
        </p>
        <p>
          We may even collect a cookie from your browser to make the website
          experience better. If you wish, you can turn off the collection of
          cookies. However, you should remember that turning off cookies may
          restrict your proper use of the website.
        </p>
        <p>
          We work with AppLovin to deliver rewarded ads in our platform.
          AppLovin, may be collecting, processing, and sharing Personal Data for
          advertising purposes (including interest-based advertising), and that
          third-party measurement partners may be collecting and processing
          Personal Data as part of the Open Measurement Working Group to perform
          ad measurement and other services. For more information about
          AppLovin’s collection and use of your information visit: “
          <a href="https://www.applovin.com/privacy/”">
            https://www.applovin.com/privacy/”
          </a>
          .
        </p>
        <p>
          Playtime (adjoe) is a third feature that is integrated with our app.
          Playtime allows users to earn in-app currency by playing third-party
          games and collects devices’ application usage from users. The
          processing of the data is necessary to recommend, via system messages,
          the installation of the app available in Playtime that matches users’
          interests and calculates the bonuses acquired as a result of users’
          use of the corresponding apps. For more information, please refer to
          Playtime (adjoe) Data Privacy.”{" "}
          <a href="https://adjoe.zone/legal/datenschutz.html">
            https://adjoe.zone/legal/datenschutz.html
          </a>
          .
        </p>
        <p>
          None of the advertisements you may encounter in rewarded
          advertisements are affiliated with Gamer Arena, and Gamer Arena shall
          not be held responsible for any actions taken after viewing these
          advertisements.
        </p>
        <p>
          In order to provide you with an efficient service, we and our service
          providers may need to transfer your personal data from one country to
          another in the EU and EFTA regions.
        </p>
        <h4 id="personal-data-retention-periods">
          Personal Data Retention Periods
        </h4>
        <p>
          GAMER ARENA retains the personal data it processes in accordance with
          the PDPL for the periods stipulated in the relevant legislation or
          required by the processing purpose. In our Personal Data Retention and
          Disposal Policy, these periods are approximately as follows:
        </p>
        <p>Membership records: 10 years</p>
        <p>
          All records related to accounting and financial transactions: 10 years
        </p>
        <p>Cookies: max. 3 years</p>
        <p>Traffic information for online visitors: 2 years</p>
        <p>Resumes: 1 year</p>
        <p>
          Personal Data of Client Companies: 10 years after the legal
          relationship ceases
        </p>
        <p>
          Personal data regarding suppliers: 10 years after the legal
          relationship ceases
        </p>
        <h4 id="related-person-rights">Related Person Rights</h4>
        <p>
          The rights of the Relevant Person with respect to the personal data
          processed by GAMER ARENA pursuant to Article 11 of the PDPL are listed
          below:
        </p>
        <ul>
          <li>Learning whether personal data is processed or not,</li>
          <li>
            If personal data has been processed, requesting information about
            it,
          </li>
          <li>
            To learn the purpose of processing personal data and whether it is
            used in accordance with the purpose,
          </li>
          <li>
            Knowing the third parties to whom personal data is transferred in
            the country or abroad,
          </li>
          <li>
            Requesting correction of personal data in cases of incomplete or
            incorrect processing,
          </li>
          <li>
            Requesting the deletion or destruction of personal data within the
            framework of the conditions stipulated in Article 7 of the PDPL,
          </li>
          <li>
            Requesting notification of the transactions made pursuant to
            subparagraphs (d) and (e) to third parties to whom personal data has
            been transferred,
          </li>
          <li>
            Objecting to the emergence of a result against the person
            himself/herself by analyzing the processed data exclusively through
            automated systems,
          </li>
          <li>
            Requesting the compensation for the damage in case of loss due to
            unlawful processing of personal data.
          </li>
          <li>
            In order to exercise your rights over your personal data, you can
            make your application and exercise your rights using the methods
            specified in the &quot;Application Form&quot; issued in accordance
            with Article 13 of the PDPL in the GAMER ARENA Platform.
          </li>
        </ul>
        <h4 id="terms-of-disposal-of-personal-data">
          Terms of Disposal of Personal Data
        </h4>
        <ul>
          <li>
            GAMER ARENA keeps the personal data it processes through the website
            and Platform for the periods stipulated by the relevant laws and/or
            for the periods required by the purpose of processing, pursuant to
            Articles 7, 17 of the PDPL and Article 138 of the Turkish Penal
            Code. In the event that these periods expire, it will delete,
            destroy, or anonymize Personal Data in accordance with the
            provisions of the By-Law on the Erasure, Destruction or
            Anonymization of Personal Data.
          </li>
          <li>
            Deletion of personal data by GAMER ARENA means the process of making
            personal data inaccessible and unusable for the relevant users in
            any way. GAMER ARENA creates and implements a user-level access
            authorization and control matrix for this. It takes the necessary
            measures to perform the deletion in the database.
          </li>
          <li>
            Destruction of personal data by GAMER ARENA refers to the process of
            making personal data inaccessible, unrecoverable, and unusable by
            anyone in any way.
          </li>
          <li>
            Anonymization of personal data by GAMER ARENA means that personal
            data cannot be associated with an identified or identifiable natural
            person under any circumstances, even if it is matched with other
            data.
          </li>
          <li>
            GAMER ARENA explains in detail the methods of deletion, destruction,
            and anonymization and the technical and administrative measures it
            has taken within the scope of the Personal Data Retention and
            Disposal Policy prepared in accordance with the By-Law on Erasure,
            Destruction, or Anonymization of Personal Data.
          </li>
          <li>
            While you have the right to access, correct, delete, and restrict
            your data, you also have the right to make data portability
            requests.
          </li>
        </ul>
        <h4 id="changes-to-be-made-in-the-clarification-text">
          Changes To Be Made In The Clarification Text
        </h4>
        <p>
          GAMER ARENA may make changes to this Clarification Text at any time.
          These changes will become effective immediately upon the publication
          of the revised version of the Clarification Text. In order for you to
          be informed about the changes in this Clarification Text, the relevant
          notifications shall be made to the related persons.
        </p>
        <h4 id="limitation-of-liability">Limitation of Liability</h4>
        <p>
          Within the scope of membership and for creating an account, you give
          your explicit consent in advance for the Company to create an account
          and profile for you from all the information you provide and share on
          the Platform, including KYC processes. All kinds of legal, criminal,
          and/or administrative responsibility for disputes that may arise
          regarding the accuracy of the information shared in this context
          personally belong to you. During the account creation process, if the
          platform redirects you to another pop-up page or tab, the information
          you fill in on this page and the files you upload are processed and
          saved in the systems of the said company or third party, not the
          Company’s systems. The Company has no right to intervene in such and
          similar cases. When you enter any information by following the links
          provided on the Platform, the Company has no responsibility for the
          content and accuracy of this information. The Company has no
          obligation to investigate the accuracy of the information you provide
          and share on the Platform, including KYC processes, within the scope
          of Membership and for creating an account. Any legal, criminal, and/or
          administrative responsibility for the disputes that may arise
          regarding the accuracy of the information given shall be yours. With
          the explicit consent given you have been presumed to accept the use of
          your data within the scope of this Privacy Policy. Your data may be
          processed/transferred/collected by GAMER ARENA and an authorised Third
          Party with your explicit consent where applicable law requires in
          accordance with the GDPR. Neither party shall do, nor cause or permit
          to be done, anything which may knowingly or intentionally result in a
          breach of the applicable Law. You can object to the processing of your
          data and withdraw your consent if the processing is based on your
          consent GAMER ARENA does not knowingly collect personal data from
          children. Since the players below age of 13 are not permitted in the
          system, in cases of violation of this the GAMER ARENA shall not be
          responsible for the consequences.
        </p>
        <h4 id="prevailing-language">Prevailing Language</h4>
        <p>
          This Privacy Policy is made in Turkish and English. In the event of a
          dispute as to the terms of this Privacy Policy, the Turkish version
          shall prevail
        </p>
        <h4 id="your-obligations">Your obligations</h4>
        <p>
          If you breach any of the obligations specified below, the Company may
          recourse to you for any damages, including those to third parties, or
          may take legal actions and remedies to compensate for the damages, and
          may suspend or close your account, provided that you are notified when
          necessary. The Company evaluates the legality of the information
          provided and its compliance with obligations, taking into account
          fundamental rights and freedoms such as freedom of expression and
          legality reasons such as public interest.
        </p>
        <ul>
          <li>
            All personal data entered by you on the platform must be accurate
            and up-to-date.
          </li>
          <li>
            If requested by the Company, your personal data must be verified and
            approved.
          </li>
          <li>
            If changes or updates are required in personal data, the relevant
            sections must be updated. Otherwise, your current information shall
            be deemed to be correct.
          </li>
          <li>
            <p>The account belongs only to you. Therefore; you agree</p>
            <ul>
              <li>To choose a strong and secure password;</li>
              <li>To keep the password safe and confidential;</li>
              <li>Not to give any part of the account to anyone else and</li>
              <li>To comply with the laws and Terms of Use in this context</li>
            </ul>
          </li>
          <li>
            <p>
              Even if all reasonable precautions are taken, you are responsible
              for any actions taken regarding your account, other than the
              hijacking of your account by unrelated persons, or for systemic
              reasons beyond your control.
            </p>
          </li>
          <li>
            It is expressly prohibited to open an
            imitation/parody/fake/misleading account on the platform by using
            any person&#39;s information.
          </li>
          <li>
            You may not include personal data, especially sensitive personal
            data, of unrelated third parties on the platform unless it is
            necessary and suitable for its purpose, and you cannot violate
            confidentiality agreements with any legal or natural person.
          </li>
          <li>
            You cannot include content such as insults, slander, threats, etc.,
            which can be considered crimes under the Turkish Penal Code No.
            5237, on the platform.
          </li>
          <li>
            You may not include content that promotes or supports illegal or
            criminal activities or organizations on the platform.
          </li>
          <li>
            You cannot open more than one account, put your account on sale, and
            have others use it for your own benefit or the benefit of a third
            party, for deception, spoofing, unfair gain, or other reasons.
          </li>
          <li>
            You cannot include content that violates the intellectual property
            rights of third parties, such as trademarks and copyrights.
          </li>
        </ul>
        <h4 id="purposes-of-processing-your-personal-data">
          Purposes of Processing Your Personal Data
        </h4>
        <ul>
          <li>
            In order to complete your membership procedures and participation in
            the platform, as well as to complete the KYC processes and take
            precautions regarding privacy and security, the information you
            share with us through the platform during and after account creation
            and membership processes is shared with third parties.
          </li>
          <li>
            When you open an account on the GA website or application, your
            information may be shared
            <ul>
              <li>
                In order to evaluate whether the account and account conditions
                are met,
              </li>
              <li>
                To examine the accounts and to identify new users who
                create/will create an account,
              </li>
              <li>
                To verify your identity data and record your information for
                future confirmation,
              </li>
              <li>
                With our lawyers, to exercise our right to defense when
                necessary.
              </li>
            </ul>
          </li>
          <li>
            In order to provide services, we share the data with third parties
            based abroad and with our service providers, who are based abroad
            and with whom we have a data controller/processor relationship.
          </li>
        </ul>
        <p>
          Your personal data shall be retained for the maximum period specified
          in the relevant legislation or required for the purpose for which they
          are processed, and in any case, for the statutory limitation periods.
        </p>
        <p>
          The Company has the right to contact you, to notify you about new
          products and system improvements, and to reach and send e-mails to you
          for other “NON-COMMERCIAL” purposes via the contact addresses
          specified by you during registration, membership, and account creation
          on the Platform, or updated by you later. By having read this
          Clarification Text, it will be deemed that you know and understand
          that the Company will engage in the above-mentioned communication
          activities, unless the Company has stated otherwise. However, if you
          do not want to receive commercial e-mail, you can unsubscribe from the
          e-mail list with one click, through the account settings, or by using
          the &quot;I want to unsubscribe&quot; link in the e-mail sent to you.
        </p>
        <h4 id="anti-money-laundering">Anti-Money Laundering</h4>
        <ul>
          <li>
            All transactions will be checked to prevent money laundering. GA
            shall report all suspicious transactions to the relevant authorities
            in Turkey. If GA becomes aware of any suspicious activity related to
            any of the games on its website, we may report it to the Financial
            Intelligence Analysis Unit (FIAU). GA may suspend, block, or close
            an account and withhold its balances upon request under the
            Anti-Money Laundering Act.
          </li>
          <li>
            GAMER ARENA&#39;s obligations under AML legislation preclude
            commercial terms.
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrivacyPolicy;
