import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonComp from "./ButtonComp";
import { Grid, List, ListItem, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import wallett from "../assets/wallet-02.svg";
import plus from "../assets/Plus.svg";
import { textAlign } from "@mui/system";
import { AppContext } from "../App";
import { useTheme } from "@emotion/react";
import { makeStyles } from "@material-ui/core";
const CardOne = (props) => {
  const {
    text = "",
    icon,
    textTwo = "",
    wallets,
    buttonn,
    onClickWallet,
    hg = "",
    textPlus = "",
  } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const { setLoginPressed, loginResponse } = useContext(AppContext);
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Grid item sm={12} sx={{ width: "100%", height: "100%" }}>
        <Card
          sx={{
            backgroundColor: "#262831",
            borderRadius: 3,
            height: matches ? "200px" : "255px",
          }}
        >
          {wallets && (
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={wallett}
                    style={{
                      width: "72px",
                      height: "72px",
                    }}
                  />{" "}
                </Box>
              </>
            </CardContent>
          )}
          <CardContent
            sx={{
              justifyContent: "center",
              height: "158px",
              mt: matches ? 0 : "3rem",
            }}
          >
            {icon && (
              <Box
                sx={{
                  direction: "row",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 0.5,
                  p: 0,
                }}
              >
                <List>
                  <ListItem sx={{ p: 0, pt: 2, justifyContent: "center" }}>
                    <Button onClick={onClickWallet}>
                      <img
                        src={plus}
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                      />
                    </Button>
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <Typography sx={{ color: "white", fontSize: "20px" }}>
                      {textPlus}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: "5%" }}>
              {" "}
              <Typography sx={{ color: "white", fontSize: "20px" }}>
                {text}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", p: 0 }}>
              <Typography
                sx={{
                  color: "#8E909A",
                  fontSize: "18px",
                  px: "40%",
                  textAlign: "center",
                }}
              >
                {textTwo}
              </Typography>
            </Box>

            {buttonn && (
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <ButtonComp
                  onClick={() => setLoginPressed(true)}
                  bg="#464853"
                  text="Login"
                />
              </CardContent>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardOne;
