import {
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

const FooterTwo = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems={matches ? 'flex-start' : 'center'}
      direction={matches ? 'row' : 'column'}
      sx={{}}
    >
      <Grid item>
        <List>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Typography sx={{ fontSize: '14px', color: '#FFFFFF' }}>
              Keşfet
            </Typography>{' '}
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            {' '}
            <Link
              href="https://about.gamerarena.com/"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Hakkımızda
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://coinmarketcap.com/currencies/gamer-arena/"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Coinmarketcap
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://www.coingecko.com/en/coins/gamer-arena"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Coingecko
              </Typography>
            </Link>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <List>
          <ListItem
            sx={{
              textAlign: 'center',
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Typography sx={{ fontSize: '14px', color: '#FFFFFF' }}>
              Topluluk
            </Typography>{' '}
          </ListItem>
          <ListItem
            sx={{
              textAlign: 'center',
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Link
              href="https://discord.com/invite/gamer-arena-652064236083871745"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Discord
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Link
              href="https://twitter.com/gamerarena_com"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Twitter
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Link
              href="https://www.instagram.com/gamerarena_com/"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Instagram
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Link
              href="https://www.facebook.com/gamerarenacom/"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Facebook
              </Typography>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: matches ? 'flex-start' : 'center',
            }}
          >
            <Link
              href="https://t.me/GamerArena_GAU"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Telegram
              </Typography>
            </Link>
          </ListItem>
        </List>
      </Grid>
      <Grid item>
        <List>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Typography sx={{ fontSize: '14px', color: '#FFFFFF' }}>
              Platform
            </Typography>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://gamerarena.com/landing/v1"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Ana Sayfa
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="http://help.gamerarena.com/tr/"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Yardım Merkezi
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="mailto:info@gamerarena.com"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Kurumsal İletişim
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://gamerarena.com/landing/v1?action=FAQ"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                SSS
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://gamerarena.com/terms-of-service"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Kullanım Şartları
              </Typography>
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: matches ? 'flex-start' : 'center' }}>
            <Link
              href="https://gamerarena.com/privacy-policy"
              target="_blank"
              rel="noopener"
              sx={{
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {' '}
              <Typography sx={{ fontSize: '12px', color: '#FFFFFFB2' }}>
                Aydınlatma Metni
              </Typography>
            </Link>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default FooterTwo;
