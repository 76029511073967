import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
const ButtonDetail = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    bg = '',
    bgg = '',
    border = '',
    jc = 'flex-end',
    text = '',
    src,
    color = '#0F0F12',
    onClick,
    icon,
    width = '160px',
    height = '48px',
    disabled,
    icons,
  } = props;
  return (
    <Box sx={{ px: '1%' }}>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant="outlined"
        sx={{
          backgroundImage: disabled ? 'transparent' : bgg,
          backgroundColor: disabled ? 'transparent' : bg,
          textTransform: 'none',
          width: width,
          height: height,
          borderRadius: 6,
          color: color,
          p: 0,
          fontSize: matches ? '16px' : '14px',
          borderColor: disabled && '#FFFFFF',
          border: border,
        }}
        startIcon={
          icon && (
            <img
              src={src}
              style={{
                width: '20px',
                height: '18px',
                color: disabled && 'transparent',
              }}
            />
          )
        }
      >
        {text}
      </Button>
    </Box>
  );
};

export default ButtonDetail;
