import {
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import ButtonDetail from "./ButtonDetail";
import close from "../assets/close.svg";

import RankY from "../assets/RankY.svg";

const DoneWalletPage = ({ handleClose, styleProps, topup = false }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));


  return (
    <>
      <Box
        sx={{
          width: matches ? "560px" : "auto",
          height: "80%",
          backgroundColor: "#181920",

          border: "2px solid #262831",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.32)",
          ...styleProps?.container,
        }}
      >
        <DialogActions sx={{ pr: 3, py: 2 }}>
          <img
            src={close}
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
            }}
          />
        </DialogActions>
        <Box sx={{ px: matches ? "7%" : "1%" }}>
          <DialogContent
            sx={{ display: "flex", justifyContent: "center", p: 0 }}
          >
            <img
              src={RankY}
              style={{
                width: "155px",
                height: "160px",
              }}
            />
          </DialogContent>
          <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              {topup
                ? " Top-up has completed "
                : " Withdraw request has completed"}
            </Typography>
          </DialogContent>
          <DialogContent
            sx={{ display: "flex", justifyContent: "center", py: 0 }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#8E909A",
                textAlign: "center",
              }}
            >
              {topup
                ? "Now, you can see your current balance."
                : " Your withdrawal request has been received. The request will be checked and, if appropriate, transfer will take place within 24 hours."}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ m: "7%", mt: "15%", justifyContent: "center" }}>
            <ButtonDetail
              onClick={handleClose}
              text="Done"
              jc="center"
              width="295px"
              color="#FFFFFF"
              bgg="linear-gradient(95.41deg, #7021BF 0%, #4C32CE 100%)"
            />
          </DialogActions>{" "}
        </Box>
      </Box>
    </>
  );
};

export default DoneWalletPage;
