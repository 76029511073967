import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import CardOne from "../components/CardOne";
import TabComp from "../components/TabComp";
import TitleComp from "../components/TitleComp";
import TableWithdraws from "../components/TableWithdraws";
import { AppContext } from "../App";

const MainPage = () => {
   const {
      data
    } = useContext(AppContext);
  
  return (
    <Box sx={{minHeight:"90vh"}}>
       <TabComp />
       {data && data.length > 0 &&  <TableWithdraws/>}
      
    </Box>
 );

};

export default MainPage;
