import { Dialog } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FirstModalPage from "./FirstModalPage";
import SecondModalPage from "./SecondModalPage";
import DoneWalletPage from "./DoneWalletPage";
import { AppContext } from "../App";

const ModalWallet = ({ openWallet, setOpenWallet }) => {
  const { setText } = useContext(AppContext);
  const [pageIndex, setPageIndex] = useState(0);

  const [summaryState, setSummaryState] = useState(null);
  const handleClickOpen = () => {
    setOpenWallet(true);
  };
  const handleClose = () => {
    setOpenWallet(false);
    window.localStorage.removeItem("withdraw");
    setText("");
  };
  useEffect(() => {
    let timer;
    if (!openWallet) {
      timer = setTimeout(() => {
        setPageIndex(0);
      }, 500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [openWallet]);

  return (
    <div>
      <Dialog
        open={openWallet}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FirstModalPage
          title=" Withdraw from GamerArena Wallet"
          handleClose={handleClose}
          setPageIndex={setPageIndex}
          onPressContinue={(amount) => {
            setPageIndex(1);
            setSummaryState(amount);
          }}
          styleProps={{
            container: {
              ...(pageIndex !== 0 && { display: "none" }),
            },
          }}
        />
        <SecondModalPage
          textt="Withdraw"
          handleClose={handleClose}
          setPageIndex={setPageIndex}
          summaryState={summaryState}
          styleProps={{
            container: {
              ...(pageIndex !== 1 && { display: "none" }),
            },
          }}
        />

        <DoneWalletPage
          styleProps={{
            container: {
              ...(pageIndex !== 2 && { display: "none" }),
            },
          }}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
};

export default ModalWallet;
