import { Dialog } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FirstModalPage from "./FirstModalPage";
import SecondModalPage from "./SecondModalPage";
import DoneWalletPage from "./DoneWalletPage";
import TopupGAPage from "./TopupGAPage";
import DoneTopupModal from "./DoneTopupModal";
import { AppContext } from "../App";

const ModalTopup = ({ openWalletTopup, setOpenWalletTopup }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [summaryState, setSummaryState] = useState(null);
  const handleClickOpen = () => {
    setOpenWalletTopup(true);
  };
  const handleClose = () => {
    setOpenWalletTopup(false);
  };

  useEffect(() => {
    let timer;
    if (!openWalletTopup) {
      timer = setTimeout(() => {
        setPageIndex(0);
      }, 500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [openWalletTopup]);

  return (
    <div>
      <Dialog
        open={openWalletTopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FirstModalPage
          title="Top-up GamerArena Wallet"
          fee="0"
          MinimumAmount={1}
          handleClose={handleClose}
          setPageIndex={setPageIndex}
          onPressContinue={(amount) => {
            setPageIndex(1);
            setSummaryState(amount);
          }}
          topup
          styleProps={{
            container: {
              ...(pageIndex !== 0 && { display: "none" }),
            },
          }}
        />
        <SecondModalPage
          textt="Top-up Gamer Arena Wallet"
          handleClose={handleClose}
          setPageIndex={setPageIndex}
          summaryState={summaryState}
          topup
          styleProps={{
            container: {
              ...(pageIndex !== 1 && { display: "none" }),
            },
          }}
        />
        <TopupGAPage
          styleProps={{
            container: {
              ...(pageIndex !== 2 && { display: "none" }),
            },
          }}
          handleClose={handleClose}
        />
        <DoneWalletPage
          topup
          styleProps={{
            container: {
              ...(pageIndex !== 3 && { display: "none" }),
            },
          }}
          handleClose={handleClose}
        />
        <DoneTopupModal
          styleProps={{
            container: {
              ...(pageIndex !== 4 && { display: "none" }),
            },
          }}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
};

export default ModalTopup;
