import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBMtLXYYw0kHpWrBAklDLcDN8geAZWg48o",
  authDomain: "gauarena-7584e.firebaseapp.com",
  projectId: "gauarena-7584e",
  storageBucket: "gauarena-7584e.appspot.com",
  messagingSenderId: "828921360899",
  appId: "1:828921360899:web:4aca791b4dc3ef570c9436",
  measurementId: "G-S6TT6BK6YN",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

const appleProvider = new firebase.auth.OAuthProvider("apple.com");
export const signInWithApple = () => auth.signInWithPopup(appleProvider);

export default firebase;
