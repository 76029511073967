import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import ButtonComp from "./ButtonComp";
import MainPage from "../pages/MainPage";
import { auth } from "../services/firebase";
import { AppContext } from "../App";
import { Avatar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { PROFILE_IMAGES } from "../config/default";
import LoginComp from "./LoginComp";
import logoo from "../assets/logoo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ModalOne from "./ModalOne";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";

const drawerWidth = 240;

// eslint-disable-next-line no-unused-vars

const DrawerComp = (props) => {
  const { windows } = props;
  const {
    setLoginResponse,
    loginResponse,
    loginPressed,
    setLoginPressed,
    setWalletLogin,
    walletLogin,
  } = useContext(AppContext);

  const { open: openWeb3 } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const btnhandler = () => {
    if (loginResponse) {
      openWeb3();
    } else {
      setLoginPressed(true);
    }
  };

  const drawer = (
    <div>
      {loginResponse && (
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
            mt: 2,
          }}
        >
          <img
            src={logoo}
            style={{
              width: "151px",
              height: "20px",
            }}
          />
        </Box>
      )}
    </div>
  );

  const container =
    windows !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", backgroundColor: "#0F0F12" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100%)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ backgroundColor: "#0F0F12" }}>
          {/* <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            {' '}
            <img
              src={GAlogo}
              style={{
                width: '24px',
                height: '24px',
              }}
            />
          </Box> */}

          <Box flexGrow={1} />
          {/* <CompSelect /> */}
          {!loginResponse ? (
            <ButtonComp
              onClick={() => setLoginPressed(true)}
              text="Login"
              jc="flex-end"
            />
          ) : (
            <>
              <ModalOne open={open} setOpen={setOpen} />
              {!walletLogin && (
                <ButtonComp
                  text="Connect MetaMask"
                  jc="flex-end"
                  onClick={btnhandler}
                />
              )}
              {walletLogin && (
                <Typography
                  sx={{ fontSize: "16px", color: "white", fontWeight: 700 }}
                >
                  {walletLogin?.substring(0, 6) +
                    "..." +
                    walletLogin?.substring(walletLogin?.length - 4)}
                </Typography>
              )}

              <Avatar
                sx={{ ml: "2%" }}
                alt={
                  loginResponse?.nickname?.length <= 10
                    ? loginResponse?.nickname
                    : loginResponse?.nickname.substr(0, 10) + "..."
                }
                src={PROFILE_IMAGES + loginResponse?.avatar}
              />

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                  marginLeft: "8px",
                  color: "#FFFFFF",
                  textOverflow: "ellipsis",
                }}
              >
                {loginResponse?.nickname.length <= 10
                  ? loginResponse?.nickname
                  : loginResponse?.nickname.substr(0, 10) + "..."}
              </Typography>
              <IconButton aria-label="arrow" onClick={handleClick}>
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
              <Menu
                sx={{
                  py: 0,
                  "& .MuiMenu-list": {
                    background: "#262831",
                    borderRadius: 0,
                  },
                }}
                id="basic-menu"
                anchorEl={anchorEl}
                open={opens}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  sx={{ py: 0, backgroundColor: "#262831", color: "white" }}
                  onClick={() =>
                    auth.signOut().then(() => {
                      if (isConnected) disconnect();
                      setLoginResponse(null);
                      setWalletLogin(null);
                      setAnchorEl(null);
                      localStorage.clear();
                    })
                  }
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Toolbar disableGutters />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
          mt: "68px",
        }}
      >
        <MainPage />
        {loginPressed && (
          <LoginComp
            open={loginPressed}
            handleClose={() => setLoginPressed(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default DrawerComp;
