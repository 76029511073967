import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAccount } from "wagmi";
import React, { useCallback, useContext, useEffect, useState } from "react";
import vektor from "../assets/Vector.svg";
import GAU from "../assets/GAU.svg";
import ButtonDetail from "./ButtonDetail";
import iconWallet from "../assets/IconWallet.svg";
import icon2 from "../assets/Icon2.svg";
import { AppContext } from "../App";
import ModalWallet from "./ModalWallet";
import ModalOne from "./ModalOne";
import { API_URL } from "../config/default";
import ModalTopup from "./ModalTopup";
import ModalDisconnect from "./ModalDisconnect";
import { isMobile } from "react-device-detect";
import { auth } from "../services/firebase";

const CardDetail = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const [openConnectWallet, setOpenConnectWallet] = useState(false);
  const [openWalletTopup, setOpenWalletTopup] = useState(false);
  const { address, isConnected } = useAccount();
  const {
    loginResponse,
    walletLogin,
    setWalletLogin,
    setLoginPressed,
    openWallet,
    setOpenWallet,
  } = useContext(AppContext);

  const [open, setOpen] = React.useState(false);

  const btnhandler = () => {
    if (loginResponse) {
      if (walletLogin !== address) {
        // res[0] for fetching a first wallet
        updatePublicAddress(address);
      } else {
        setOpen(true);
      }
    } else {
      setLoginPressed(true);
    }
  };

  const handleNetworkSwitch = () => {
    console.log("aaa", walletLogin, address);
    if (!walletLogin || !isConnected) {
      btnhandler();
    } else {
      setOpenWalletTopup(true);
    }
  };
  const updatePublicAddress = useCallback(
    (publicAddress) => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-access-token", loginResponse?.token);

      console.log("loginResponse?.token", loginResponse?.token);

      var raw = JSON.stringify({
        publicAddress,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      fetch(`${API_URL}api/profile/updatePublicAddress`, requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.ok) {
            return Promise.resolve(response.json()); // This will end up in SUCCESS part
          } else if (response.status === 401) {
            auth.signOut().then(() => {
              // setLoginResponse(null);
              setWalletLogin(null);
              localStorage.clear();
            });
          }
          const responseInJson = await Promise.resolve(response.json());
          return Promise.reject(responseInJson);
        })
        .then((res) => {
          console.log("updatePublicAddress", res);
          setWalletLogin(publicAddress);
        })
        .catch((error) => console.log("error", error));
    },
    [loginResponse?.token, setWalletLogin]
  );
  return (
    <>
      <Card
        sx={{
          background:
            "linear-gradient(112.1deg, #EB2A44 17.84%, #8C49E2 103.4%)",
          borderRadius: 6,
          height: matches ? "200px" : "255px",
        }}
      >
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: "1%" }}
          >
            <Grid item justifyContent="flex-start">
              <List>
                {/* <ListItem sx={{ py: 0 }}>
                  <Typography sx={{ fontSize: "16px", color: "#FFFFFF" }}>
                    GamerArena Wallet
                  </Typography>
                </ListItem> */}
                <ListItem>
                  <Typography
                    sx={{ opacity: 0.7, fontSize: "12px", color: "#FFFFFF" }}
                  >
                    @
                    {loginResponse?.nickname?.length <= 10
                      ? loginResponse?.nickname
                      : loginResponse?.nickname.substr(0, 10) + "..."}
                  </Typography>
                </ListItem>
                <ListItem sx={{ py: 0 }}>
                  <Typography sx={{ fontSize: "12px", color: "#FFFFFF" }}>
                    {loginResponse?.userId?.substring(0, 3) +
                      "..." +
                      loginResponse?.userId?.substring(
                        loginResponse?.userId?.length - 3
                      )}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid item justifyContent="flex-end">
              <Tooltip arrow>
                <img
                  src={vektor}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent sx={{ p: 0 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: "1%" }}
          >
            <Grid item justifyContent="flex-start">
              <List>
                <ListItem>
                  <img
                    src={GAU}
                    style={{
                      width: "49.74px",
                      height: "50px",
                    }}
                  />
                  <Typography
                    sx={{ fontSize: "24px", color: "#FFFFFF", px: 1 }}
                  >
                    {Number(loginResponse?.wallet).toFixed(2) ?? "-"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", color: "#FFFFFF", mt: "3%" }}
                  >
                    GAU
                    {/* . $10.5 */}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              sx={{ pl: matches ? 0 : "5%" }}
              justifyContent={matches ? "flex-end" : "center"}
            >
              <List>
                <ListItem sx={{ p: 0, justifyContent: "flex-end" }}>
                  <ButtonDetail
                    disabled={!walletLogin}
                    text="Top-Up"
                    onClick={handleNetworkSwitch}
                    bg="#FFFFFF"
                    src={iconWallet}
                    icon
                    width={matches ? "160px" : "143px"}
                  />
                  <ModalTopup
                    openWalletTopup={openWalletTopup}
                    setOpenWalletTopup={setOpenWalletTopup}
                  />
                  <ButtonDetail
                    disabled={!walletLogin}
                    onClick={() => {
                      if (!walletLogin) {
                        setOpenConnectWallet(true);
                      } else setOpenWallet(true);
                    }}
                    text="Withdraw"
                    color="white"
                    border={1.5}
                    width={matches ? "160px" : "143px"}
                    src={icon2}
                    icon
                  />
                </ListItem>
                <ModalWallet
                  openWallet={openWallet}
                  setOpenWallet={setOpenWallet}
                />
                <ModalOne
                  open={openConnectWallet}
                  setOpen={setOpenConnectWallet}
                />
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ModalDisconnect open={open} setOpen={setOpen} />
    </>
  );
};

export default CardDetail;
